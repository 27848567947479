import React from 'react'
import styled from 'styled-components'
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons'

export default function DropdownButton({
	label = 'Dropdown Button',
	prefixIcon = UserOutlined,
	showPrefixIcon = true,
	showChildren = false,
	children,
}) {
	const PrefixIcon = prefixIcon
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Container>
				{showChildren ? (
					children
				) : (
					<PrefixIcon
						style={{ padding: showPrefixIcon ? 8 : '8px 0', opacity: showPrefixIcon ? 1 : 0 }}
					/>
				)}
				{label && <span style={{ marginRight: 10, marginBottom: 2 }}>{label}</span>}
			</Container>
			<CaretIcon className='caret-icon' />
		</div>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	border: 1px solid var(--border-color);
	border-right: none;
	border-radius: 2px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
`
const CaretIcon = styled(CaretDownOutlined)`
	padding: 5px;
	font-size: 20px;
	border: 1px solid var(--border-color);
	border-radius: 2px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
`
