import { SET_SETTINGS } from './types'

// Set app settings
export const setSettings = (settings) => {
	return { type: SET_SETTINGS, payload: settings }
}

// Update settings like 'default_route'
export const updateSettings = (updates) => {
	return { type: SET_SETTINGS, payload: updates }
}
