/*
  Detail Error Log of AJAX req 
*/
import React from 'react'
import { Modal, notification } from 'antd'
import { ExclamationCircleOutlined, RightOutlined } from '@ant-design/icons'

import keys from '../config/keys'
import { appRouteHistory } from '../App'
import { getViewingItemId } from './helpers'
import asyncLogError from './asyncLogError'

const commonMsg = 'Something went wrong. Check your internet connection and try again!'
const sessionExpTitle = `Session expired.`

const handleError = (error, notify = false, filename, args) => {
	const { asNotification = false, ...popupProps } = args || {}
	if (filename) {
		console.error(`AJAX Req Error in ${filename}`)
	} else {
		console.error('AJAX Req Error')
	}
	console.warn('===============Error Info==================')
	let title = 'ERROR'
	let msg = commonMsg
	let statusCode
	let okLabel
	let okRoute
	let onOk

	if (error instanceof CustomError) {
		console.error(
			`${error.name}: ${error.title ? `[${error.title}] ` : ''}${error.message} (${error.code})`
		)
		if (error.title) title = error.title
		if (error.message) msg = error.message
	}

	if (error.response) {
		statusCode = error.response.status
		console.error('Error Data: ', error.response.data)
		console.error('Error Status Code: ', statusCode)
		console.error('Error Headers: ', error.response.headers)
		// Server responded with a status code that falls out of the range of 2xx
		const { error: errorMsg, error_description, message, data: error_data } = error.response.data
		const { error_header, button_label, redirect_route } = error_data || {}

		if (button_label) okLabel = button_label
		if (redirect_route) okRoute = redirect_route

		if (errorMsg) {
			msg = errorMsg
			if (msg === 'invalid_token' && error.response.status === 401) {
				// Remove token from local storage
				localStorage.removeItem(keys.accessToken)
				// Kick to login page
				setTimeout(() => window.location.reload(), 2000)
				title = 'Account archived'
				msg = 'Please contact support.'
			} else if (msg === 'invalid_grant') {
				msg =
					'Refresh token is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.'
				// Remove token from local storage
				localStorage.removeItem(keys.accessToken)
			} else if (msg === 'untrusted_source') {
				msg = error_description
			}
		} else if (statusCode === 401) {
			// In other tab user logged out / bb_session table is dropped
			msg = `Your session has expired, as a security measure you have been logged out. Click 'OK' to log back in.`
			onOk = () => window.location.reload()
		} else if (statusCode === 404) {
			msg = "Sorry, can't find the data you're looking for."
		} else if (message) {
			msg = message
		}

		if (error_header) {
			title = error_header
		} else {
			switch (statusCode) {
				case 400:
					title = `Validation Error`
					break
				case 401:
					title = sessionExpTitle
					break
				case 403:
					title = `Permission Error`
					break
				case 404:
					title = `Not Found Error`
					break
				default:
					// 500 or anything else
					title = `System Error`
			}
		}
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		console.error('Error: no response was received\n------------------\n', error.request)
	} else {
		// Something happened in setting up the request that triggered an Error
		console.error('Error: setting up the request\n-------------------\n', error.message)
	}
	console.warn('Error Config:\n-----------------\n', error.config)
	console.warn('==============End Error Info=============')
	const finalMsg = msg.replace('ERROR Error:', '').replace('Error:', '')

	setTimeout(() => {
		asyncLogError({ error: error, title, desc: finalMsg })
	}, 300)

	if (finalMsg && notify) {
		const titleEl = (
			<>
				{title}
				{/* {statusCode ? ` (${statusCode})` : null} */}
			</>
		)
		const bodyEl = <div dangerouslySetInnerHTML={{ __html: finalMsg }} />
		if (asNotification) {
			notification.error({ message: titleEl, description: bodyEl, duration: 6, ...popupProps })
		} else {
			const showModal = () => {
				if (statusCode === 503) return

				if (statusCode === 401) {
					const el = document.getElementById(keys.inactivityModalCloseTriggerBtnID)
					el && el.click()
				}

				Modal.confirm({
					className: `emma-popup-confirm-modal ${keys.C_APP_ERROR_POPUP_CLASS} error-code-${
						statusCode || ''
					}`,
					title: <b>{titleEl}</b>,
					icon: <ExclamationCircleOutlined />,
					content: bodyEl,
					okText: (
						<>
							{okLabel || 'OK'} <RightOutlined />
						</>
					),
					onOk: (close) => {
						close()
						if (okRoute) {
							const id = getViewingItemId(window.location.href)
							appRouteHistory.push(okRoute.replace(':id', id))
							window.location.reload()
						}
						onOk && onOk()
					},
					cancelButtonProps: { style: { opacity: 0, pointerEvents: 'none' } },
				})
			}

			if ([commonMsg].includes(finalMsg) || [sessionExpTitle].includes(title)) {
				// Check if it's already showing
				const el = document.querySelector(`.${keys.C_APP_ERROR_POPUP_CLASS}`)
				if (!el) showModal()
			} else {
				showModal()
			}
		}
	}
	return finalMsg
}

export default handleError

export class CustomError extends Error {
	constructor(message, code, title) {
		super(message)
		this.name = this.constructor.name
		this.code = code
		this.title = title
		// Ensure correct prototype chain
		Object.setPrototypeOf(this, new.target.prototype)
	}
}
