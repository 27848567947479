import axios from 'axios'

export const setAxiosDefaultHeader = (flag) => {
	if (flag === true) {
		axios.defaults.crossDomain = true
		axios.defaults.withCredentials = true
	} else if (flag === false) {
		axios.defaults.crossDomain = false
		axios.defaults.withCredentials = false
	}
}
