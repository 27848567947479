import React from 'react'
import he from 'he'
import { round } from 'lodash'
import { saveAs } from 'file-saver'
import sha256 from 'crypto-js/sha256'
import Base64 from 'crypto-js/enc-base64'
import { htmlToText } from 'html-to-text'
import confirm from 'antd/lib/modal/confirm'
import { FaCircle } from 'react-icons/fa6'
import { LockTwoTone, RedoOutlined } from '@ant-design/icons'
import { Popover, Modal, message, Alert, Button, Row, Col, Form, Space, Spin } from 'antd'
import {
	ExclamationCircleOutlined,
	QuestionCircleOutlined,
	QuestionCircleTwoTone,
	ReloadOutlined,
	RightOutlined,
	WarningTwoTone,
} from '@ant-design/icons'

import isEmpty from './isEmpty'
import keys from '../config/keys'
import store from '../redux/store'
import vars from '../config/vars'
import Tag from '../components/DataModule/CustomTemplate/ViewAndEditTemplates/Events/elements/Tag'
import { handleForgotPassword } from '../containers/TheHeaderDropdown'
import { prepareTime, previewTime } from './timeHelpers'

const { eventBadgeColors } = vars

export const SPINNING_UI = (
	<Row justify='center' className='w-100'>
		<Spin size='small' />
	</Row>
)

export const ILO = {
	// Inline Loadable Options
	fallback: SPINNING_UI,
}

export function distributePrice(totalPrice, quantity) {
	if (quantity === 1) {
		// If quantity is 1, return the total price rounded to 2 decimal places
		return [round(totalPrice, 2)]
	}

	const averagePrice = totalPrice / quantity
	const roundedPrice = round(averagePrice, 2) // Rounds to 2 decimal places
	const totalRoundedPrice = roundedPrice * quantity
	const remainder = round(totalPrice - totalRoundedPrice, 2)
	const prices = []

	for (let i = 0; i < quantity; i++) {
		if (i === quantity - 1) {
			// Add the remainder to the last part
			prices.push(round(roundedPrice + remainder, 2))
		} else {
			prices.push(roundedPrice)
		}
	}

	return prices
}

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export const sanitizeApiVal = (val) => {
	if (val === '0' || val === 0) return 0
	return he.decode(htmlToText(val, { wordwrap: 130 }) || '')
}

export const generateList = (n) => Array.from({ length: n }, (_, i) => i + 1)

export const isScript = (input = '') =>
	input?.includes('&lt;script&gt;') ||
	input?.includes('&lt;/script&gt;') ||
	input?.includes('<script>') ||
	input?.includes('</script>')

export const truncate = (input = '', length = 5, tooltip = false, title) => {
	const val = he.decode(htmlToText(input ?? '').substring(0, length) ?? '')
	return input.length > length ? (
		tooltip ? (
			<Popover
				title={title}
				getPopupContainer={_getPopupContainer}
				content={
					isScript(input) ? (
						<strong style={{ color: 'tomato' }}>
							Quick view is unavailable as it's contain JavaScript.
						</strong>
					) : (
						<div dangerouslySetInnerHTML={{ __html: input }} />
					)
				}
			>
				{val}...
			</Popover>
		) : (
			`${val}...`
		)
	) : (
		input
	)
}

// Provide the parent class name
export const removeAttribute = (className = 'ant-form-item-label', attr = 'title') => {
	const labels = document.getElementsByClassName(className)
	for (var i = 0; i < labels.length; i++) {
		const label = labels[i]?.children?.[0]
		label && label.removeAttribute(attr)
	}
}

export const previewHTML = (HTML) => {
	if (window.previewWindow) {
		window.previewWindow.close()
	}

	window.previewWindow = window.open()
	if (window.previewWindow?.document) {
		window.previewWindow.document.write(buildPreviewHtml(HTML))
		window.previewWindow.document.close()
	}
}

const buildPreviewHtml = (HTML = '') => {
	// console.log(HTML)
	return `
		<!Doctype html>
		<html>
			<head>
				<title>Preview Content</title>
				<style>
					html,body{
						height: 100%;
						margin: 0;
						padding: 0;
						overflow: auto;
						background-color: #f1f2f3;
					}
					.container{
						box-sizing: border-box;
						width: 1000px;
						max-width: 100%;
						min-height: 100%;
						margin: 0 auto;
						padding: 30px 20px;
						overflow: hidden;
						background-color: #fff;
						border-right: solid 1px #eee;
						border-left: solid 1px #eee;
					}
					.container img,
					.container audio,
					.container video{
						max-width: 100%;
						height: auto;
					}
					.container p{
						white-space: pre-wrap;
						min-height: 1em;
					}
					.container pre{
						padding: 15px;
						background-color: #f1f1f1;
						border-radius: 5px;
					}
					.container blockquote{
						margin: 0;
						padding: 15px;
						background-color: #f1f1f1;
						border-left: 3px solid #d1d1d1;
					}
				</style>
			</head>
			<body>
				<div class="container">${HTML}</div>
			</body>
		</html>
	`
}

export const getDataSlice = (data = [], paginationCurrentPage = 1, paginationPageSize = 10) => {
	// Normally you should get the data from the server
	const start = paginationCurrentPage === 1 ? 0 : (paginationCurrentPage - 1) * paginationPageSize
	const end = start + paginationPageSize
	// console.log(start, end)
	return data.slice(start, end)
}

export function generateRandomString(length) {
	var text = ''
	var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
	for (var i = 0; i < length; i++) {
		text += possible.charAt(Math.floor(Math.random() * possible.length))
	}
	return text
}

export const getAuthState = () => {
	const string = generateRandomString(15)
	localStorage.setItem(keys.appAuthState, string)
	return string
}

export function generateCodeChallenge() {
	const code_verifier = generateRandomString(128)
	localStorage.setItem(keys.appAuthCodeVerifier, code_verifier)
	const code_challenge = base64URL(sha256(code_verifier))
	localStorage.setItem(keys.appAuthCodeChallenge, code_challenge)
	return code_challenge
}

export function base64URL(string) {
	return string.toString(Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
}

export const getCSVContent = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsText(file, 'ISO-8859-1')
		reader.onload = () => resolve(Buffer.from(reader.result).toString('base64'))
		reader.onerror = (error) => reject(error)
	})
}

export const getBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = (error) => reject(error)
	})
}

// get an image blob from url using fetch
const getImageBlob = function (url) {
	return new Promise(async (resolve) => {
		const response = await fetch(url)
		const blob = response.blob()
		resolve(blob)
	})
}

// convert a blob to base64
export function blobToBase64(blob) {
	return new Promise((resolve, _) => {
		const reader = new FileReader()
		reader.onloadend = () => resolve(reader.result)
		reader.readAsDataURL(blob)
	})
}

// combine the previous two functions to return a base64 encode image from url
export const getBase64OfImageUrl = async function (url) {
	const blob = await getImageBlob(url)
	const base64 = await blobToBase64(blob)
	return base64
}

export function isValidBase64(input) {
	try {
		return btoa(atob(input)) === input
	} catch (e) {
		return false
	}
}

export function calculateBase64UrlSize(base64Url) {
	// Check if the input is a string
	if (typeof base64Url !== 'string') {
		throw new Error('Input must be a string.')
	}

	// Remove data URI prefix if present
	const base64Data = base64Url.replace(/^(data:[^;]+;base64,)/, '')

	// Check if the input is a valid base64 encoded string
	if (!isValidBase64(base64Data)) {
		throw new Error('Input is not a valid base64 encoded string.')
	}

	// Convert base64 data to binary
	const binaryData = atob(base64Data)

	// Calculate the size in bytes
	const sizeInBytes = binaryData.length

	return sizeInBytes
}

export function getQueryVariable(variable) {
	var query = window.location.search.substring(1)
	var vars = query.split('&')
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('=')
		if (decodeURIComponent(pair[0]) === variable) {
			return decodeURIComponent(pair[1])
		}
	}
	console.log(`Query variable '${variable}' not found`)
}

/**
 * Get all the URL parameters
 * @param  {String} search  window.location.search
 * @return {Object}         The URL parameters
 */
export const getAllQueryVariables = function (search, log = false) {
	const params = new URLSearchParams(search)
	let paramObj = {}

	for (const key of params.keys()) {
		let value = params.get(key)
		if (key.endsWith('idNUM')) value = convertToNumber(value)
		paramObj[key] = value
	}

	delete paramObj.refresh // It's for app refresh only
	if (log) console.log('Query variables: ', paramObj)

	return paramObj
}

export const getQueryParamFromUrlSuffix = (str, key, base = window.location.origin) => {
	const url = new URL(str, base)
	const params = new URLSearchParams(url.search)
	const val = params.get(key)
	return val
}

export function getCols(columns, maxColumns = 3) {
	const multiplier = 24 / maxColumns
	const cols = columns ? (Number(columns) > maxColumns ? maxColumns : Number(columns)) : 1
	// console.log({ cols })
	return Math.round(multiplier * cols)
}

export const getElementSize = (element) => {
	if (!element) return { height: 0, width: 0 }

	const style = getComputedStyle(element)
	const width = element.offsetWidth || 0
	const height = element.offsetHeight || 0
	const marginLeft = parseInt(style.marginLeft || '0')
	const marginRight = parseInt(style.marginRight || '0')
	const marginTop = parseInt(style.marginTop || '0')
	const marginBottom = parseInt(style.marginBottom || '0')

	return { height: height + marginTop + marginBottom, width: width + marginRight + marginLeft }
}

export const sleep = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms))
}

export const getContainer = (node, property = 'parentElement') => node[property] ?? document.body

export const Money = (amount, currency = 'GBP') =>
	new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currency.toUpperCase(),
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(amount)

export const checkAntDFieldsError = (form) =>
	!!form.getFieldsError().filter(({ errors }) => errors.length).length

export const showConfirm = (
	callback,
	title = 'Are you sure about this action?',
	desc = '',
	okType = 'danger',
	others = {}
) => {
	const {
		okLabel = 'Yes',
		titleBold = false,
		warnIcon = false,
		twoToneColor = 'red',
		directProps = {},
		showCancel = true,
	} = others || {}
	confirm({
		className: 'bulk-confirm-modal',
		title: titleBold ? <b>{title}</b> : title,
		icon: warnIcon ? (
			<WarningTwoTone twoToneColor={twoToneColor} />
		) : (
			<QuestionCircleTwoTone twoToneColor={twoToneColor} />
		),
		content: desc,
		onOk: () => callback(),
		onCancel() {
			console.log('Action cancelled.')
		},
		okText: (
			<>
				{okLabel} <RightOutlined />
			</>
		),
		okButtonProps: {
			style: { backgroundColor: vars.emmaColor, color: '#fff', borderColor: vars.emmaColor },
		},
		cancelButtonProps: {
			style: !showCancel ? { pointerEvents: 'none', opacity: 0 } : {},
		},
		okType,
		...directProps,
	})
}

export const findTemplate = (requestedTemplateName, available_templates = []) => {
	let requestedTemplate
	if (!requestedTemplateName || isEmpty(available_templates)) return null

	available_templates.forEach((x) => {
		if (x.name === requestedTemplateName) {
			requestedTemplate = x
		} else {
			const children = x.children || []
			children.forEach((y) => {
				if (y.name === requestedTemplateName) {
					requestedTemplate = y
				}
			})
		}
	})

	return requestedTemplate
}

export const getValForCSV = (col, dataItem) => {
	const key = col.field
	const fieldName = `${key}_display`

	if (['raw', 'number'].includes(col.type)) {
		return dataItem[key]
	} else if (['datetime', 'date'].includes(col.type)) {
		if (dataItem[fieldName]) return dataItem[fieldName]
		return previewTime(dataItem[key], { type: col.type, showEmpty: true })
	}

	return dataItem.hasOwnProperty(fieldName) ? dataItem[fieldName] : dataItem[key]
}

export const getCSVData = (tableData, columnDefs, { headerProperty = 'label' } = {}) => {
	const columns = columnDefs.filter((x) => x.field !== 'action')
	const csvHeader = columns.map((x) => x[headerProperty])
	const csvBody = (tableData ?? []).map((dataItem) => {
		const array = []
		columns.forEach((col) => {
			const key = col.field

			let value = getValForCSV(col, dataItem) ?? ''

			if (value) {
				if (col.type === 'text' || col.type === 'textarea') {
					if (typeof value === 'string') {
						value = he.decode(value || '')
					}
				}

				// Not needed anymore as 'number' is not string('_display') now
				if (typeof value === 'string') {
					value = value.split('&pound;').join('')
					value = value.split('&euro;').join('')
					value = value.split('&dollar;').join('')

					if (col.type === 'number') {
						value = value.split('$').join('')
					}
				}

				// Same logic is in getDifferentCSVData()
				if (keys.mobileFields.includes(key)) {
					value += `\t` // For Excel to treat as a mobile number
				}

				if (col.type === 'file') {
					value = value?.filename ?? JSON.stringify(value)
				} else if (typeof value === 'object') {
					value = JSON.stringify(value)
				}
			}
			array.push(value)
		})
		return array
	})

	return [csvHeader, ...csvBody]
}

export const cleanAndAdjustData = ({ data = {}, fields = [] }) => {
	if (isEmpty(fields) || isEmpty(data)) return data
	if (Array.isArray(data)) return data

	const newData = { ...data }

	fields.forEach((col) => {
		const { field, type } = col
		const value = data[field]
		if (type === 'text') {
			// Convert all number values to string because schema says it should be type text
			if (Object.prototype.hasOwnProperty.call(data, field)) {
				if (typeof value === 'number') newData[field] = `${value}`
			}
		}
	})

	return newData
}

export const createRichTextEditorState = (htmlString = null) => {
	return htmlString
}

const modalBodyQuery = `ant-modal-body`
export const _getPopupContainer = (triggerNode, origin) => {
	const length = document.getElementsByClassName(modalBodyQuery).length
	const modalBody = document.getElementsByClassName(modalBodyQuery)[length - 1]
	const fullscreenContainer = document.getElementById(keys.FullscreenContainer)
	const _default = fullscreenContainer ?? document.getElementById(keys.appBodyId)
	// console.log(_default, modalBody)
	if (modalBody) {
		if (origin === keys.TOOLTIP_ORIGIN_FORM_FIELD) {
			const el = getContainer(triggerNode.closest('div')).querySelector(
				`div.ant-form-item-control-input`
			)
			return el
		}
		return getContainer(modalBody)
	} else {
		return _default
	}
}

export function makeRegexFromLiteralNotationString(str) {
	const [, patternContent, flags] = str.match(/^\/(.+)\/([a-zA-Z]*)$/)
	return new RegExp(patternContent, flags)
}

export function showOfflinePaymentOptionWarning({
	title = 'Offline Payment Option enabled',
	content = "You have enabled the Offline Payment Option which may prevent guests from paying via card on the Microsite. Click ‘Send’ to send the payment links anyway or 'Cancel' to not send the payment links.",
	okText = 'Send',
	cancelText = 'Cancel',
	onOk,
	type = 'confirm',
}) {
	const { microsite_payment_bank_transfer_optionYN } =
		store.getState().currentData?.viewAndEdit || {}

	if (microsite_payment_bank_transfer_optionYN) {
		Modal[type]({
			title,
			icon: <ExclamationCircleOutlined />,
			content,
			okText,
			cancelText,
			onOk,
		})
	} else {
		onOk && onOk()
	}
}

export const removeDisplayValues = (obj = {}) => {
	const clone = { ...obj }
	Object.keys(clone).forEach((key) => {
		if (key.endsWith('_display')) {
			delete clone[key]
		}
	})
	return clone
}

export const removeUnderscoreValues = (obj = {}) => {
	const clone = { ...obj }
	Object.keys(clone).forEach((key) => {
		if (key.startsWith('_')) {
			delete clone[key]
		}
	})
	return clone
}

export const getReadableImageSize = (size) => {
	let _size = size
	const fSExt = ['Bytes', 'KB', 'MB', 'GB']
	let i = 0

	while (_size > 900) {
		_size /= 1024
		i++
	}

	const exactSize = Math.round(_size * 100) / 100 + ' ' + fSExt[i]
	console.log('FILE SIZE = ', exactSize)
	return exactSize
}

// export const getAppStaticData = async () => {
// 	const req = await fetch(`/app.json?time=${Date.now()}`, {
// 		cache: 'no-store',
// 		headers: { 'Cache-Control': 'no-cache' },
// 	})
// 	const response = await req.json()
// 	return response
// }

export const chevronTop = <i className='emma-color cil-chevron-top' style={{ fontSize: 20 }} />
export const chevronBottom = (
	<i className='emma-color cil-chevron-bottom' style={{ fontSize: 20 }} />
)

export const generateHiddenInitialValuesFromFieldsDef = (fields) => {
	const values = {}
	if (isEmpty(fields)) return values
	const _fields = fields.filter(
		(x) => (x.type === 'hidden' || x.hidden === true) && x.required && !isEmpty(x.default_value)
	)
	_fields.forEach((y) => (values[y.field] = y.default_value))
	return values
}

export const getDynamicTableProps = ({ template, loading }) => {
	// console.log({ template })
	const { sticky_table_headerYN } = template ?? {}

	const props = {}

	if (sticky_table_headerYN && !loading) {
		props['sticky'] = { offsetHeader: vars.stickyOffsetHeader }
	}

	return props
}

export const isTemplate = (input = '', desire = '') => {
	if (input === desire || input?.startsWith(`${desire}_`)) {
		return { templateName: input, ok: true }
	} else {
		return { templateName: '_______NOT____DESIRE____UI_______', ok: false }
	}
}

export const getActiveInactiveColor = (value) => {
	let bg = undefined
	switch (value) {
		case 'Active':
		case true:
			bg = vars.emmaLightGreen
			break
		case 'Inactive':
		case false:
			bg = vars.emmaYellow
			break
		case 'Invited':
			bg = vars.emmaLime
			break
		case `Archived`:
			bg = vars.emmaAlmostBlackColor
			break
		default:
			break
	}
	return bg
}

const modalTitleQuery = `ant-modal-title`
export const setAntdModalTitle = ({ title }) => {
	if (!title) return
	const titleEls = document.getElementsByClassName(modalTitleQuery)
	const length = titleEls.length
	const topEl = titleEls[length - 1]
	if (topEl) topEl.textContent = title
}

export const getVoucherStatusColor = (status = '') => {
	switch (status.toLowerCase()) {
		case 'pending':
			return ['#A6A6A6', '#fff']
		case 'redeemed':
			return ['#CBF0CB', '#000']
		case 'confirmed':
			return ['#86B5B3', '#fff']
		case 'completed':
			return ['#406972', '#fff']
		case 'expiring soon':
		case 'expiring':
			return ['#F7CEA0', '#000']
		case 'expired':
			return ['#E0BE5F', '#fff']
		case 'cancelled':
			return ['#EE8383', '#fff']
		case 'archived':
			return ['#303038', '#fff']
		case 'all':
			return ['#3C444E', '#fff']

		default:
			return []
	}
}

const emma_events_tab_viewlist_base = `emma_events_tab_viewlist`
export const emma_events_items_viewlist_base = `emma_events_items_viewlist`
const emma_client_team_members_tab_viewlist_base = `emma_client_team_members_tab_viewlist`

export const getTabItemContentPrefix = ({ name }) => {
	let ui
	switch (name) {
		case `${emma_client_team_members_tab_viewlist_base}_active`:
		case `Active`:
			ui = <FaCircle color={`#77B7B4`} size={16} />
			break

		case `Inactive`:
			ui = <FaCircle color={vars.emmaYellow} size={16} />
			break

		case `${emma_client_team_members_tab_viewlist_base}_invited`:
		case `Invited`:
			ui = <FaCircle color={vars.emmaLime} size={16} />
			break

		case `${emma_client_team_members_tab_viewlist_base}_archived`:
		case `Archived`:
			ui = <FaCircle color={vars.emmaAlmostBlackColor} size={16} />
			break

		case `${emma_client_team_members_tab_viewlist_base}`: // All
			break

		default:
			break
	}

	return ui
}
export const getTabBadgeColor = (templateName) => {
	if (templateName.startsWith(`emma_vouchers_tab_`)) {
		return getVoucherStatusColor(templateName.replace(`emma_vouchers_tab_`, ''))
	}

	switch (templateName) {
		case `Live`: // Live Events
		case `${emma_events_tab_viewlist_base}`: // Live Events
		case `${emma_events_items_viewlist_base}_live`: // Live Events
		case `${emma_client_team_members_tab_viewlist_base}_active`:
			return [eventBadgeColors.live, '#fff']

		case `Upcoming`:
		case `${emma_events_tab_viewlist_base}_upcoming`: // Upcoming Events
		case `${emma_events_items_viewlist_base}_upcoming`: // Upcoming Events
		case `${emma_client_team_members_tab_viewlist_base}_invited`:
			return [eventBadgeColors.upcoming, '#fff']

		case `Completed`:
		case `${emma_events_tab_viewlist_base}_completed`: // Completed Events
		case `${emma_events_items_viewlist_base}_completed`: // Completed Events
		case `${emma_client_team_members_tab_viewlist_base}_archived`:
			return [eventBadgeColors.completed, '#fff']

		case `${emma_events_tab_viewlist_base}_all`: // All Events
		case `${emma_events_items_viewlist_base}_all`: // All Events
		case `${emma_client_team_members_tab_viewlist_base}`: // All
			return [eventBadgeColors.all, '#fff']

		default:
			return [vars.primaryColor, '#fff']
	}
}

export const getEventTagColor = (status = '') => {
	const _status = status?.toLowerCase?.()
	let color = eventBadgeColors.live

	switch (_status) {
		case 'upcoming':
			color = eventBadgeColors.upcoming
			break

		case 'completed':
			color = eventBadgeColors.completed
			break
		default:
			break
	}

	return color
}

export const getEventStatusTag = (status = '', templateName = '', style = {}, className = '') => {
	if (!status && templateName) {
		let _status = ''
		let [bgColor] = getTabBadgeColor(templateName)
		if ([emma_events_tab_viewlist_base].includes(templateName) || templateName.endsWith(`_live`)) {
			_status = `LIVE`
		} else if (templateName.endsWith(`_upcoming`)) {
			_status = `UPCOMING`
		} else if (templateName.endsWith(`_completed`)) {
			_status = `COMPLETED`
		} else if (templateName.endsWith(`_all`)) {
			_status = `ALL EVENTS`
		}

		return <Tag value={_status} bgColor={bgColor} style={style} className={className} />
	}

	return (
		<Tag
			value={status?.toUpperCase?.()}
			bgColor={getEventTagColor(status)}
			style={style}
			className={className}
		/>
	)
}

export const checkSizeIsRestricted = ({
	_val,
	_label,
	sizeMb,
	notify = false,
	popup = true,
	modalTitle,
	modalContent = `The file you’re trying to upload is too large, please reduce the file size and try again.`,
	modalContentSuffix = `You can hover over the 'i' icon for optimal file size information.`,
	fileField,
}) => {
	let notOk = false
	if (_val && fileField) {
		const label = _label ?? fileField.label
		const _sizeBytes = fileField?.range?.max
		const _sizeMb = _sizeBytes ? _sizeBytes / 1024 / 1024 : sizeMb
		const size = _val.size
		if (size && _sizeMb) {
			console.log(`Checking ${label} is <= ${_sizeMb}MB.`)
			const isLessThan = size / 1024 / 1024 <= _sizeMb
			if (!isLessThan) {
				notOk = true
				notify && message.warn(`${label} file size must be smaller than ${_sizeMb}MB!`, 6)
				const _modalContent = `${modalContent} ${modalContentSuffix ?? ''}`
				popup &&
					Modal.warning({
						centered: true,
						icon: <WarningTwoTone twoToneColor={'red'} />,
						title: modalTitle || `${label} file size restriction`,
						content: _modalContent,
						okText: (
							<>
								OK <RightOutlined />
							</>
						),
					})
			}
		}
	}

	return notOk
}

export const putValueInSuffixEndpoint = (suffix = '', values = {}) => {
	if (!suffix || isEmpty(values)) return suffix

	if (!isEmpty(values)) {
		for (const key in values) {
			const value = values[key]
			suffix = suffix.replace(`:${key}`, value)
		}
	}

	return suffix
}

export function parseFieldsFromString(str) {
	if (!str) return []
	const fields = []
	const regex = /:\w+/g
	const matches = str.match(regex)

	if (matches) {
		matches.forEach((match) => {
			const field = match.substring(1) // remove the ":" character
			fields.push(field)
		})
	}

	return fields
}

export const getErrorAlert = ({ msg, className = 'mt-3', onRetry }) => (
	<Alert
		className={className}
		message={msg || 'Something went wrong. Try again!'}
		showIcon
		type='error'
		action={
			onRetry ? (
				<Button type='primary' icon={<ReloadOutlined />} onClick={onRetry}>
					Try Again
				</Button>
			) : undefined
		}
	/>
)

export const getFilterButton = (
	filterButtonLoading,
	form,
	label = 'Filter',
	disableCheck = true,
	elements = [],
	rowProps = { justify: 'end', align: 'middle', gutter: 12, wrap: false, className: 'mt-2' }
) => {
	return (
		<Row {...rowProps}>
			{elements.map((el, i) => (
				<Col key={i}>{el}</Col>
			))}

			<Col>
				<Form.Item shouldUpdate={true} noStyle>
					{() => {
						const disabled = disableCheck
							? !form.isFieldsTouched() ||
							  form.getFieldsError().filter(({ errors }) => errors.length).length > 0
							: false

						return (
							<Button
								type='primary'
								loading={filterButtonLoading}
								htmlType='submit'
								icon={<i className='cil-filter mr-1' style={{ verticalAlign: 'text-bottom' }} />}
								disabled={disabled}
							>
								{label}
							</Button>
						)
					}}
				</Form.Item>
			</Col>
		</Row>
	)
}

export function appendQueryParams(obj, urlSuffix = '') {
	if (isEmpty(obj)) return urlSuffix
	const queryParams = Object.entries(obj)
		.filter(
			([key, value]) =>
				value !== null && value !== undefined && !key.startsWith('__') && !key.endsWith('_Ignore')
		)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&')
	if (!queryParams) return urlSuffix
	return `${urlSuffix}?${queryParams}`
}

export function pixelToPercentage(pxValue, parentPxValue) {
	return ((pxValue / parentPxValue) * 100).toFixed(2)
}

export function percentageToPixel(percentageValue, parentPxValue) {
	return (percentageValue / 100) * parentPxValue
}

export function getTransformValues(transform) {
	const translateRegex = /translate\(([-.\d]+)px, ([-.\d]+)px\)/
	const rotateRegex = /rotate\(([-.\d]+)deg\)/

	const translateMatch = transform.match(translateRegex)
	const rotateMatch = transform.match(rotateRegex)

	const tx = translateMatch ? parseFloat(translateMatch[1]) : 0
	const ty = translateMatch ? parseFloat(translateMatch[2]) : 0
	const rotate = rotateMatch ? parseFloat(rotateMatch[1]) : 0

	return { tx, ty, rotate }
}

const parseDateDefaultVal = (value) => {
	return value === 'now' ? undefined : value
}

export const getApiProvidedInitialValues = (source, fields = []) => {
	const initValues = {}
	const sourceNotEmpty = !isEmpty(source)

	fields.forEach((x) => {
		const isOkay = sourceNotEmpty
			? true
			: x.hasOwnProperty('default_value') &&
			  x.default_value !== null &&
			  x.default_value !== undefined

		if (isOkay) {
			const type = x.type
			const field = x.field
			const value = source?.[field] ?? x.default_value
			// console.log({ source, field, value });
			if (['datetime', 'date'].includes(type)) {
				initValues[field] = value ? prepareTime(parseDateDefaultVal(value, type), { type }) : value
			} else if (['text-parse-html', 'textarea-rich'].includes(type)) {
				const val = value === '<p></p>' ? value.replace('<p></p>', '') : value
				initValues[field] = createRichTextEditorState(val)
			} else if (type === 'textarea') {
				const val = value === '<p></p>' ? value.replace('<p></p>', '') : value
				initValues[field] = val
			} else {
				initValues[field] = value
			}
		}
	})

	return initValues
}

export async function preloadImage(path) {
	if (!path) return
	const img = new Image()
	img.src = path
	await img.decode()
	return img.src
}

export const getPopupConfirmCommonConfig = (props = {}) => {
	return {
		className: 'emma-popup-confirm-modal',
		title: <b>Changes not saved!</b>,
		icon: <ExclamationCircleOutlined />,
		content: `Click 'Cancel' to go back and save your changes or 'Exit Without Saving' to leave this page without saving.`,
		okText: (
			<>
				Exit Without Saving <RightOutlined />
			</>
		),
		cancelText: 'Cancel',
		...props,
	}
}

export const getUnseatGuestPopupConfirmCommonConfig = (done, props = {}) => {
	const { p1, p2, title, ...rest } = props || {}
	return {
		...getPopupConfirmCommonConfig(),
		icon: <QuestionCircleOutlined />,
		title: <>{title || 'Unseat Guest?'}</>,
		content: (
			<>
				<p className='mb-2'>{p1 || 'This guest is seated on the Table Planner.'}</p>
				<p>{p2 || 'Would you like to automatically unseat the guest?'}</p>
			</>
		),
		okText: (
			<>
				Yes <RightOutlined />
			</>
		),
		cancelText: 'No',
		onOk: (close) => {
			close()
			done({ void_remove_seatYN: true })
		},
		onCancel: (close) => {
			close()
			done({ void_remove_seatYN: false })
		},
		...rest,
	}
}

export const checkRedirect = (_obj, navigate, options = {}) => {
	const { redirect_route } = _obj || {}
	if (redirect_route) {
		if (navigate) {
			navigate(redirect_route, options)
		} else {
			window.location.href = redirect_route
		}
	}
}

export function getFileBlobUrl(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()

		reader.onloadend = () => {
			resolve(reader.result)
		}

		reader.onerror = () => {
			reject(new Error('Error reading file.'))
		}

		reader.readAsDataURL(file)
	})
}

export function removeQueryVariables(urlString, variablesToRemove) {
	// This does not handle complex query parameters with brackets to remove, such as 'filters[activeYN]'
	// Mainly for removing simple params like limit, page etc.
	let url = new URL(urlString)
	let params = new URLSearchParams(url.search)

	variablesToRemove.forEach((variable) => {
		params.delete(variable)
	})

	url.search = params.toString()
	return url.href
}

export function onlyKeepQueryVariables(
	variablesToKeep = ['template'],
	urlString = window.location.href,
	returnPath = true
) {
	// This does not handle complex query parameters with brackets to remove, such as 'filters[activeYN]'
	// Mainly for removing all params except template.
	let url = new URL(urlString)
	let params = new URLSearchParams(url.search)

	params.keys().forEach((key) => {
		if (!variablesToKeep.includes(key)) params.delete(key)
	})

	url.search = params.toString()

	if (returnPath) {
		const path = url.pathname + url.search
		return path
	}

	return url.href
}

export const downloadFile = (args) => {
	const { data, type, name } = args
	const isTextFile = type.includes('text/')
	const config = { type }
	if (isTextFile) {
		config['type'] = `${config.type};charset=utf-8,%EF%BB%BF`
	}
	const blob = new Blob([data], config)
	saveAs(blob, name)
}

export const shouldShowETicketBtn = (dataRow) => {
	return (
		dataRow.ticket_type === 'EVENT' &&
		!dataRow.voidYN &&
		dataRow.enable_eticketYN &&
		dataRow.paidYN &&
		dataRow.rsvp_statusISLIST_Awaiting_Yes_No === 'Yes'
	)
}

export const getETicketErrMsg = (row) => {
	let msg = ''

	if (row.ticket_type !== 'EVENT') {
		msg = `Ticket type is not EVENT.`
	} else if (row.voidYN) {
		msg = `Ticket is marked as void.`
	} else if (!row.enable_eticketYN) {
		msg = `E-Ticket functionality is not enabled for this ticket.`
	} else if (!row.paidYN) {
		msg = `Ticket is not marked as paid.`
	} else if (row.rsvp_statusISLIST_Awaiting_Yes_No !== 'Yes') {
		msg = `RSVP status is not 'Yes' for this ticket.`
	}

	return msg
}

export const getETicketErrModalContent = (
	rows,
	{
		titleKey = 'ticket_typeISbb_emma_event_ticketsID_display',
		fnameKey = 'first_name',
		lnameKey = 'last_name',
	} = {}
) => {
	if (isEmpty(rows) || !Array.isArray(rows)) return null

	return (
		<Space direction='vertical' size={`small`} className='w-100'>
			{rows.map((row, i) => {
				const name = `${row[fnameKey] || ''} ${row[lnameKey] || ''}`.trim()
				return (
					<Alert
						key={i}
						className='w-100'
						message={
							<>
								{row[titleKey]}
								{name ? ` (${name})` : ''}
							</>
						}
						description={<>Error: {getETicketErrMsg(row)}</>}
						type='error'
						showIcon
					/>
				)
			})}
		</Space>
	)
}

export function modifyLinksInHTML(htmlString) {
	// If the input HTML string is empty or undefined, return an empty string
	if (!htmlString) return ''

	// Create a temporary DOM element to parse the HTML string
	const tempElement = document.createElement('div')
	tempElement.innerHTML = htmlString

	// Find all anchor elements in the parsed HTML
	const anchorElements = tempElement.querySelectorAll('a')

	// Get the current window's domain
	const currentDomain = window.location.origin

	// Loop through each anchor element and modify it
	anchorElements.forEach((anchor) => {
		// Get the original href attribute
		const originalHref = anchor.getAttribute('href')

		// If the href attribute is empty or undefined, skip it
		if (!originalHref) return

		// Check if the href attribute is a relative URL
		if (originalHref.startsWith('/') && !originalHref.startsWith('//')) {
			// Convert relative URL to absolute URL by appending the current domain
			anchor.setAttribute('href', `${currentDomain}${originalHref}`)
		} else if (!originalHref.startsWith('http://') && !originalHref.startsWith('https://')) {
			// If it doesn't start with 'http://' or 'https://', add 'https://' as a prefix
			anchor.setAttribute('href', `https://${originalHref}`)
		}

		// Add 'target="_blank"' attribute to open links in a new window
		anchor.setAttribute('target', '_blank')
	})

	// Convert the modified DOM back to an HTML string
	const modifiedHTMLString = tempElement.innerHTML

	return modifiedHTMLString
}

export function getImageDimensions(imageSource) {
	return new Promise((resolve, reject) => {
		const img = new Image()

		img.onload = () => {
			// Image loaded successfully
			resolve({
				success: true,
				width: img.width,
				height: img.height,
			})
		}

		img.onerror = () => {
			// Image failed to load
			reject({
				success: false,
				width: null,
				height: null,
				error: 'Failed to load the image',
			})
		}

		img.src = imageSource
	})
}

/* Remove the duplicate/unnecessary min/max rule from number input validation rules set */
// export const adjustNumberInputValidationRules = (rules) => {
// 	if (rules.length < 2) return rules
// 	const adjustedRules = {}

// 	for (const [index, rule] of rules.entries()) {
// 		const { min, max } = rule

// 		if (min !== undefined || max !== undefined) {
// 			if (min !== undefined) {
// 				const key = `rule_min`
// 				if (!adjustedRules[key]) {
// 					adjustedRules[key] = { ...rule }
// 				} else if (!adjustedRules[key].min || min > adjustedRules[key].min) {
// 					adjustedRules[key] = { ...rule }
// 				}
// 			}

// 			if (max !== undefined) {
// 				const key = `rule_max`
// 				if (!adjustedRules[key]) {
// 					adjustedRules[key] = { ...rule }
// 				} else if (!adjustedRules[key].max || max > adjustedRules[key].max) {
// 					adjustedRules[key] = { ...rule }
// 				}
// 			}
// 		} else {
// 			adjustedRules[`rule_${index}`] = { ...rule }
// 		}
// 	}

// 	return Object.values(adjustedRules)
// }

export const sendVoucherHideList = [
	'booking_informationISsmallplaintextbox',
	'voucher_attachmentISfile',
	'voucher_type_name',
	'voucher_type_idNUM',
	'redeem_questionsISsmallplaintextbox',
	'redeem_email',
]

export function removeLastExtension(fullFileName) {
	// Check if the full file name contains a dot
	const dotIndex = fullFileName.lastIndexOf('.')

	if (dotIndex !== -1) {
		// Split the full file name by dots
		const parts = fullFileName.split('.')

		// Join all parts except the last one (extension)
		const fileName = parts.slice(0, -1).join('.')

		return fileName
	} else {
		// If there is no dot, return the original string
		return fullFileName
	}
}

export const getAppBodyDimension = (el) => {
	let _bodyHeight = el?.current?.clientHeight
	let _bodyWidth = el?.current?.clientWidth || '100%'

	if (_bodyHeight) {
		_bodyHeight -= 350
	} else {
		_bodyHeight = 500
	}
	localStorage.setItem('BODY_HEIGHT', _bodyHeight)
	localStorage.setItem('BODY_WIDTH', _bodyWidth)

	return { h: _bodyHeight, w: _bodyWidth }
}

export const specialProfileEditCommonProps = {
	hiddenFieldsJustVisualHidden: [
		'notification_ticketing_events_idNUM',
		'notification_fundraising_events_idNUM',
		'notification_registration_events_idNUM',
		'notification_ticketing_rsvp_events_idNUM',
		'notification_payments_events_idNUM',
	],
	needExtraPadding: false,
	removeReadOnlyFieldFromPostData: false,
	bottomSubmitRowStyles: {
		margin: `28px -32px 6px`,
	},
	directProps: {
		isSpecialProfileEdit: true,
	},
	submitButtonText: `Save Changes`,
	deleteBtnReplacement: (
		<Button
			style={{ fontWeight: 600 }}
			icon={<RedoOutlined />}
			onClick={() =>
				showConfirm(
					handleForgotPassword,
					'Please confirm you would like to send a password reset link to your email?',
					'',
					<LockTwoTone />
				)
			}
		>
			Reset Password
		</Button>
	),
}

/* Pass only view url */
export function getViewingItemId(url) {
	// Regular expression to match the ID in the URL
	const regex = /\/view\/(\d+)/
	// Match the ID using the regular expression
	const match = url.match(regex)
	// If a match is found, return the ID (group 1 in the regex match)
	if (match && match[1]) {
		return match[1]
	} else {
		// If no match is found, return null
		return null
	}
}

export const removeEmptyChildrenFromDataList = (dataList) => {
	return dataList.map((item) => {
		if (Array.isArray(item.children) && item.children.length > 0) {
			// Recursively remove empty children arrays
			item.children = removeEmptyChildrenFromDataList(item.children)
		} else {
			// If children is empty or not an array, delete it
			delete item.children
		}
		return item
	})
}

export function getElementPosition({ el, elementId, qs, advance = true } = {}) {
	const element = el || document.getElementById(elementId) || document.querySelector(qs)
	if (!element) {
		console.error('Element not found with ID:', elementId)
		return null
	}

	const rect = element.getBoundingClientRect()

	if (advance) {
		return {
			rect,
			left: rect.left + window.scrollX,
			top: rect.top + window.scrollY,
			right: rect.right + window.scrollX,
			bottom: rect.bottom + window.scrollY,
		}
	}

	const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
	const scrollTop = window.pageYOffset || document.documentElement.scrollTop

	return {
		rect,
		left: rect.left + scrollLeft,
		top: rect.top + scrollTop,
		right: window.innerWidth - rect.right - scrollLeft,
		bottom: window.innerHeight - rect.bottom - scrollTop,
	}
}

export const generateOptionDataForLinkedLogic = ({ form, field, selectOptionsDB }) => {
	let option

	const fieldKey = field.field
	if (keys.selectTypes.includes(field.type)) {
		const val = form.getFieldValue(fieldKey)
		if (!isEmpty(val)) {
			let sourceArr = []

			if (field.type === keys.selectTypes[1]) {
				sourceArr = field.select_options ?? []
			} else if (field.type === keys.selectTypes[0]) {
				sourceArr = selectOptionsDB?.[fieldKey] ?? []
			}

			for (const x of sourceArr) {
				const childArr = x.options ?? []
				if (String(x.value) === String(val)) {
					option = x
					break
				} else if (!isEmpty(childArr)) {
					for (const y of childArr) {
						if (String(y.value) === String(val)) {
							option = y
							break
						}
					}
				}
			}
		}
	}

	return option
}

// Helper function to check if a value is convertible to a number
export const isConvertibleToNumber = (value) => {
	if (value === null || value === undefined) {
		return false // Return false for null or undefined
	}
	const num = Number(value)
	return !isNaN(num) // Return true if it's a valid number, false otherwise
}

export function convertToNumber(input) {
	// If the input is null or undefined, return it as-is
	if (input === null || input === undefined) return input

	// If the input is already a number, return it early
	if (typeof input === 'number') return input

	// If the input is a string, check if it's convertible to a number
	if (typeof input === 'string') {
		if (!input) return null // ('') empty string case
		if (isConvertibleToNumber(input)) {
			return Number(input) // Convert and return the number if possible
		}
	}

	// If the input is an array, iterate over its elements
	if (Array.isArray(input)) {
		return input.map((item) => {
			if (typeof item === 'number') {
				return item // Early return if it's already a number
			} else if (isConvertibleToNumber(item)) {
				return Number(item) // Convert to a number if possible
			} else {
				return item // Return the item as it is if conversion is not possible
			}
		})
	}

	// Return the input as it is if conversion is not possible
	return input
}

export function updateUrlQuery(url, params) {
	const urlObj = new URL(url, window.location.origin) // Create a URL object, using window.location.origin for relative URLs

	// Loop through the params object and set/override query parameters
	for (const [key, value] of Object.entries(params)) {
		urlObj.searchParams.set(key, value) // This ensures no duplicate keys
	}

	return urlObj.toString() // Convert the URL object back to a string
}
