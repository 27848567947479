export default {
	appBodyId: 'bluebox-app-body',
	tagPreviewButtonID: 'tag-preview-button',
	fetchTableLayoutButtonID: 'fetch-table-layout-button',
	rerenderTableLayoutButtonID: 'rerender-table-layout-button',
	inactivityModalTriggerBtnID: 'inactivityModalTriggerBtnID',
	inactivityModalCloseTriggerBtnID: 'inactivityModalCloseTriggerBtnID',
	FullscreenContainer: '__FullscreenContainer__',
	LayoutViewWrapper: '__LayoutViewWrapper__',
	LayoutMapViewContainer: '__LayoutMapViewContainer__',
	C_APP_ERROR_POPUP_CLASS: 'common-app-error-popup',
	ROW_SPACE: 'row_space',
	PLUGIN_MODIFIED: '__PLUGIN_MODIFIED__',
	MICROSITE_NODE_SELECT_INPUT: 'MICROSITE_NODE_SELECT_INPUT',
	// For Local Storage and window vars
	AUCTION_ITEMS_UI_FILTERS: 'AUCTION_ITEMS_UI_FILTERS',
	SUBMIT_WITH_EXTRA_BTN: 'SUBMIT_WITH_EXTRA_BTN',
	MICROSITE_NODE_CHANGE_FN: 'MICROSITE_NODE_CHANGE_FN',
	FORM_FIELD_ON_CHANGE_FN_PREFIX: '___FORM_FIELD_ON_CHANGE_FN_PREFIX_',
	BULK_ADD_POPUP_SHOWING: 'BULK_ADD_POPUP_SHOWING',
	SPLASH_LOGO: 'SPLASH_LOGO',
	SPLASH_BG_COLOR: 'SPLASH_BG_COLOR',
	DEFAULT_TIMEZONE: 'DEFAULT_TIMEZONE',
	BATCH_STATUS_CHECKER_FN: 'BATCH_STATUS_CHECKER_FN',
	BATCH_STATUS_CHECKER_REFRESH_FN: 'BATCH_STATUS_CHECKER_REFRESH_FN',
	BATCH_STATUS_GET_FN: 'BATCH_STATUS_GET_FN',
	MICROSITE_NODE_DEDICATED: 'MICROSITE_NODE_DEDICATED',
	VERSION: 'version',
	BUILD: 'build',
	initAppVersion: 'INIT_APP_VERSION',
	appVersion: 'APP_VERSION',
	appBuildNumber: 'APP_BUILD_NUMBER',
	versionProcessingKey: 'VERSION_PROCESSING',
	buildProcessingKey: 'BUILD_NUMBER_PROCESSING',
	appUpdateTypeKey: 'APP_UPDATE_TYPE',
	appUpdateModalVisibleKey: 'APP_UPDATE_MODAL_VISIBLE',
	noSubmitLoading: 'NO_SUBMIT_LOADING',
	OTP_METHOD_RES: 'otp_method_res',
	OTP_METHOD_LABEL: 'otp_method_label',
	OTP_RESENT_INPUT: 'otp_resend_input',
	VIEW_CLIENT_TEAM_MEMBER: '___VIEW_CLIENT_TEAM_MEMBER',
	versionUpdateCheckedKey: 'VERSION_UPDATE_CHECKED',
	appMaintenanceEtaKey: 'appMaintenanceEtaKey',
	appMaintenancePeriodKey: 'appMaintenancePeriodKey',
	upcomingMaintenanceModalClosedFor: 'UpcomingMaintenanceModalClosedFor',
	buildUpdateCheckedKey: 'BUILD_UPDATE_CHECKED',
	lastUpdateCheckTimestamp: 'LAST_UPDATE_CHECKED_TIMESTAMP',
	updateModalTriggerBtnId: 'APP_LEVEL_UPDATE_MODAL_TRIGGER_BTN',
	appUpcomingMaintenanceModalVisible: 'appUpcomingMaintenanceModalVisible',
	globalSearchTextVar: 'globalSearchTextVar',
	maintenanceModalTriggerBtnId: 'MAINTENANCE_MODAL_TRIGGER_BTN',
	upcomingMaintenanceModalTriggerBtnId: 'UPCOMING_MAINTENANCE_MODAL_TRIGGER_BTN',
	PayByCardModalActionCancelled: 'PayByCardModalActionCancelled',
	dataViewListAction: 'viewlist',
	dataViewAction: 'view',
	dataAddAction: 'add',
	dataEditAction: 'edit',
	dataProfileAction: 'profile',
	appAuthState: 'AUTH_STATE', // this must be a random alphanumeric value that must be stored on the App and validated on the response from the endpoint (security check)
	appAuthCodeChallenge: 'AUTH_CODE_CHALLENGE',
	appAuthCodeVerifier: 'AUTH_CODE_VERIFIER',
	defaultHelpTab: 'DEFAULT_HELP_TAB',
	accessToken: 'ACCESS_TOKEN',
	redirect: 'REDIRECT',
	TEMP_API_KEY: 'TEMP_API_KEY',
	redirectAfterLogin: 'REDIRECT_AFTER_LOGIN',
	loadTemplate: 'LOAD_TEMPLATE',
	loadEventData: 'LOAD_EVENT_DATA',
	editPermissionKey: 'edit',
	editFormPermissionKey: 'edit_form',
	deletePermissionKey: 'delete',
	addPermissionKey: 'add',
	addFormPermissionKey: 'add_form',
	dataModuleViewEditSilentRefreshBtn: 'dataModuleViewEditSilentRefreshBtn',
	activityTabFilterValue: 'EVENT_ACTIVITY_TAB_FILTER_VALUE',
	guestTabFilterValue: 'EVENT_GUEST_TAB_FILTER_VALUE',
	guestTabSearchText: 'EVENT_GUEST_TAB_SEARCH_TEXT',
	PLEDGE_TAX_PAYER:
		'filters[modules][]=bb_emma_guests_donations&filters[modules][]=bb_emma_guests_pledges&filters[uk_tax_payerYN]=1',
	INDIVIDUAL_GIVING:
		'filters[module]=bb_emma_guests_pledges&filters[individual_givingISbb_emma_guests_individual_givingID]=!0',
	VIEW_ALL_TICKETS_UI: 'VIEW_ALL_TICKETS_UI',
	eventActivityTabTemplateName: 'emma_events_tab_view_activity',
	EVENT_ACTIVITY_FILTER_RAFFLE:
		'filters[module]=bb_emma_guests_tickets&filters[ticket_type]=RAFFLE',
	EVENT_ACTIVITY_FILTER_PLEDGE:
		'filters[modules][]=bb_emma_guests_donations&filters[modules][]=bb_emma_guests_pledges',
	EVENT_ACTIVITY_FILTER_TICKET_EVENT:
		'filters[module]=bb_emma_guests_tickets&filters[ticket_type]=EVENT',
	EVENT_ACTIVITY_FILTER_HIGHEST: 'filters[module]=bb_emma_guests_bids&filters[highest_bidYN]=1',
	EVENT_ACTIVITY_FILTER_ALL: 'filters[module]=bb_emma_guests_bids',
	activeArchivedSelectInputVal: 'activeArchivedSelectInputVal',
	VMS_TAB: 'integration-vms-tab',
	SALESFORCE_TAB: 'integration-salesforce-tab',
	RaisersEdgeNXT_TAB: 'RaisersEdgeNXT-tab',
	EMMA_MICROSITE_NODE_ID: 'emma-microsite-node-id',
	EMMA_MICROSITE_SERVER_NODE_ID: 'emma-microsite-server-node-id',
	addActivityAndPayClicked: 'addActivityAndPayClicked',
	AddFormSubmitBtn: 'AddFormSubmitBtn',
	selectTypes: ['lookup_select', 'select'],
	lookupTypes: ['lookup_custom', 'lookup'],
	toggleTypes: ['custom_toggle', 'toggle'],
	mobileFields: ['guest_mobile', 'mobile'],
	ADDRESS_LOOKUP_FIELD: 'address_lookup',
	emma_lots_raffle_add_edit_template: 'emma_lots_raffle_add_edit',
	emma_lots_auction_add_edit_template: 'emma_lots_auction_add_edit',
	emma_events_tab_view_pledging_template: 'emma_events_tab_view_pledging',
	emma_events_tab_view_individual_giving_template: 'emma_events_tab_view_individual_giving',
	emma_guests_agendas_add_edit_template: 'emma_guests_agendas_add_edit',
	emma_guests_tickets_add_template: 'emma_guests_tickets_add',
	emma_guests_tickets_batch_add_template: 'emma_guests_tickets_batch_add',
	emma_event_content_add_edit_template: 'emma_event_content_add_edit',
	emma_virtual_channels_add_edit_template: 'emma_virtual_channels_add_edit',
	emma_events_tickets_add_edit_template: 'emma_events_tickets_add_edit',
	emma_pledges_add_edit_template: 'emma_pledges_add_edit',
	emma_event_manage_template: 'emma_event_manage_template',
	emma_events_tables_plans_add_edit_template: 'emma_events_tables_plans_add_edit',
	emma_guests_individual_giving_add_edit_template: 'emma_guests_individual_giving_add_edit',
	TOOLTIP_ORIGIN_FORM_FIELD: 'FORM_FIELD',
	SCROLL_TO_BOTTOM: 'SCROLL_TO_BOTTOM',
	rsvp_status_field: 'rsvp_statusISLIST_Awaiting_Yes_No',
	SELECTED_COMM_TEMPLATE_WINDOW: '___SELECTED_COMM_TEMPLATE_WINDOW',
	MODULES: {
		bb_emma_invoices: 'bb_emma_invoices',
		events_tables: 'bb_emma_events_tables',
		events_tables_seats: 'bb_emma_events_tables_seats',
		clients: 'bb_emma_clients',
		suppliers: 'bb_emma_suppliers',
		clients_team_members: 'bb_emma_clients_team_members',
		events: 'bb_emma_events',
		vouchers: 'bb_emma_vouchers',
		guests: 'bb_emma_guests',
		bb_emma_catalogue_categories: 'bb_emma_catalogue_categories',
	},
	TEMPLATES: {
		emma_clients_edit_users: 'emma_clients_edit_users',
		admin_reports_strip_fees: 'emma_admin_reports_stripe_fees',
		admin_reports_events: 'emma_admin_reports_events',
		admin_reports_guests: 'emma_admin_reports_guests',
		admin_reports_activities: 'emma_admin_reports_activities',
		admin_reports_comms: 'emma_admin_reports_comms',
		reports_comms_viewlist: 'emma_admin_reports_comms_viewlist',
		communication_log_list: 'emma_events_tab_view_communication_log',
		emma_invoices_viewlist: 'emma_invoices_viewlist',
		layout_planner_table_seats_viewlist: 'emma_events_table_seats_viewlist',
		microsite_settings: 'emma_events_tab_view_settings_microsite',
		emma_guests_raffle_results: 'emma_guests_raffle_results',
		emma_guests_activity_viewlist: 'emma_guests_activity_viewlist',
		emma_guests_activity_bulk_delete_viewlist: 'emma_guests_activity_bulk_delete_viewlist',
		emma_guests_individual_giving_teams_add_edit: 'emma_guests_individual_giving_teams_add_edit',
		guest_ticketing_overview: 'emma_events_tab_view_guests_ticketing',
		emma_catalogue_categories_add_edit_view: 'emma_catalogue_categories_add_edit_view',
		add_multi_activity: 'emma_events_add_multi_activity',
		tab_view_microsite_design: 'emma_events_tab_view_microsite_design',
		emma_suppliers_comms_log: 'emma_suppliers_comms_log',
		emma_clients_comms_log: 'emma_clients_comms_log',
		catalogue_items_add_edit: 'emma_catalogue_items_add_edit',
		guests_add_edit: 'emma_guests_add_edit',
		suppliers_add_edit: 'emma_suppliers_add_edit',
		lots_auction_import_export_add: 'emma_lots_auction_import_export_add',
		lots_auction_import_export_edit: 'emma_lots_auction_import_export_edit',
	},
	ROUTE_KEYS: {
		table_planner: 'table-planner',
		reports: 'reports',
	},
	FIELDS: {
		paddle_number: 'paddle_numberNUM',
		guest_paddle_lookup: 'guest_paddle_lookup',
		guestId: `guestISbb_emma_guestsID`,
		hostId: `hostISbb_emma_guestsID`,
		eventId: `eventISbb_emma_eventsID`,
		eventTemplateId: `templateISbb_emma_events_templatesID`,
		lastLotBidId: `_last_lot_bidISbb_emma_guests_bidsID`,
		supplier_cost_priceCUR: `supplier_cost_priceCUR`,
		microsite_auction_enable: 'microsite_enable_auctionsYN',
		client_disable_popular_items_auto_add: 'client_disable_popular_items_auto_addYN',
		clientId: 'clientISbb_emma_clientsID',
		supplierId: 'supplierISbb_emma_suppliersID',
		secure_pin: 'secure_pin',
		microsite_node_key: 'microsite_nodeISbb_emma_microsite_nodesID',
	},
	TABLE_PLANNER_LAYOUT_VIEW_TYPE: { LIST: 'list', MAP: 'map' },
}
