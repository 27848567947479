import React from 'react'
import { Modal } from 'antd'
import moment from 'moment'
import { useSafeState } from 'ahooks'
import styled from 'styled-components'
import { RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import keys from '../config/keys'
import vars from '../config/vars'
import endpoints from '../config/endpoints'
import { HiddenButton } from '../components/Utils'
import CogLoader from '../components/Loading/CogLoader'
import { getAllQueryVariables } from '../utils/helpers'

function Maintenance() {
	const [appMaintenanceModalVisible, setAppMaintenanceModalVisible] = useSafeState(false)
	const [appUpcomingMaintenanceModalVisible, setAppUpcomingMaintenanceModalVisible] = useSafeState(
		false
	)

	const handleMaintenanceModal = (e) => {
		e.preventDefault()
		e.stopPropagation()

		if (appMaintenanceModalVisible) return

		setAppMaintenanceModalVisible(true)
	}

	const handleUpcomingMaintenanceModal = (e) => {
		e.preventDefault()
		e.stopPropagation()

		if (appUpcomingMaintenanceModalVisible) return

		const maintenanceEta = window[keys.appMaintenanceEtaKey]
		const maintenancePeriod = window[keys.appMaintenancePeriodKey]

		if (!maintenanceEta) return

		setAppUpcomingMaintenanceModalVisible(true)
		Modal.confirm({
			className: 'emma-popup-confirm-modal',
			title: <b>SYSTEM UPDATE WARNING</b>,
			icon: <ExclamationCircleOutlined />,
			content: <UpcomingMaintenanceModalContent eta={maintenanceEta} period={maintenancePeriod} />,
			okText: (
				<>
					OK <RightOutlined />
				</>
			),
			okButtonProps: {
				style: { backgroundColor: vars.emmaColor, color: '#fff', borderColor: vars.emmaColor },
			},
			cancelButtonProps: { type: 'default', style: { visibility: 'hidden' } },
			afterClose: () => {
				setAppUpcomingMaintenanceModalVisible(false)
			},
			onOk: async () => {
				localStorage.setItem(keys.upcomingMaintenanceModalClosedFor, maintenanceEta)
			},
			onCancel: (close) => {
				close && close()
			},
		})
	}

	const handleUpdateAvailableModal = (e) => {
		e.preventDefault()
		e.stopPropagation()

		const updateType = window[keys.appUpdateTypeKey]
		const newVersion = window[keys.versionProcessingKey]
		const newBuildNumber = window[keys.buildProcessingKey]

		if (window[keys.appUpdateModalVisibleKey]) return

		window[keys.appUpdateModalVisibleKey] = true
		Modal.confirm({
			className: 'emma-popup-confirm-modal',
			title: <b>EMMA has been updated</b>,
			icon: <ExclamationCircleOutlined />,
			content: `Good news! The EMMA App has been updated and the next ${updateType} is just a click away. Click 'OK' to refresh your page now and see the new ${updateType} immediately. `,
			okText: (
				<>
					OK <RightOutlined />
				</>
			),
			okButtonProps: {
				style: { backgroundColor: vars.emmaColor, color: '#fff', borderColor: vars.emmaColor },
			},
			cancelText: 'Remind Me Later',
			cancelButtonProps: { type: 'default', style: {} },
			onOk: async () => {
				// Step 1: Uninstall service workers
				navigator.serviceWorker.getRegistrations().then((registrations) => {
					console.log('Service workers:', registrations)
					registrations.forEach((registration) => {
						registration.unregister()
					})
				})

				// Step 2: Clear the cache
				if (caches) {
					caches.keys().then((keyList) => {
						console.log('Cache keys:', keyList)
						return Promise.all(
							keyList.map((key) => {
								return caches.delete(key)
							})
						)
					})
				}

				// Step 3: Reload the window
				setTimeout(() => {
					const timestamp = Date.now()
					let newUrl = window.location.origin + window.location.pathname
					const queryVars = getAllQueryVariables(window.location.search)
					for (let [index, key] of Object.keys(queryVars).entries()) {
						const val = queryVars[key]
						if (index === 0) {
							newUrl += `?${key}=${val}`
						} else {
							newUrl += `&${key}=${val}`
						}
					}
					const and = newUrl.includes('?') ? '&' : '?'
					window.location.replace(`${newUrl}${and}refresh=${timestamp}`)
				}, 1000)

				window[keys.appUpdateModalVisibleKey] = false
			},
			onCancel: (close) => {
				window[keys.versionUpdateCheckedKey] = newVersion
				window[keys.buildUpdateCheckedKey] = newBuildNumber
				const timestamp = new Date().valueOf()
				window[keys.lastUpdateCheckTimestamp] = timestamp
				window[keys.appUpdateModalVisibleKey] = false
				close && close()
			},
		})
	}

	return (
		<>
			<HiddenButton id={keys.updateModalTriggerBtnId} onClick={handleUpdateAvailableModal} />
			<HiddenButton
				id={keys.upcomingMaintenanceModalTriggerBtnId}
				onClick={handleUpcomingMaintenanceModal}
			/>
			<HiddenButton id={keys.maintenanceModalTriggerBtnId} onClick={handleMaintenanceModal} />

			<Modal
				destroyOnClose
				title='Notice'
				maskClosable={false}
				closable={false}
				className='ant-modal-width-full'
				visible={appMaintenanceModalVisible}
				style={{ top: 40 }}
				footer={null}
				bodyStyle={{
					minHeight: 'calc(100vh - 130px)',
					overflowY: 'scroll',
					display: 'flex',
					padding: 0,
				}}
			>
				<MaintenanceModalContent close={() => setAppMaintenanceModalVisible(false)} />
			</Modal>
		</>
	)
}

export default Maintenance

const delay = 1000 * 15 // 15 seconds

const MaintenanceModalContent = ({ close }) => {
	const _isMounted = React.useRef(false)

	const checkMaintenanceFinished = async () => {
		try {
			const req = await fetch(`${endpoints.status}`, {
				cache: 'no-store',
				// headers: { 'Cache-Control': 'no-cache' },
			})
			if ([200, 204].includes(req.status)) {
				close()
				window.location.reload()
			}
		} catch (error) {
			console.log('Maintenance is finished check error:', error)
		}
	}

	React.useEffect(() => {
		_isMounted.current = true

		const intervalHandler = setInterval(() => {
			if (_isMounted.current) {
				checkMaintenanceFinished()
			}
		}, delay)

		return () => {
			clearInterval(intervalHandler)
			_isMounted.current = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Center className='m-4'>
			<CogLoader>
				<Center>
					<h2 className='mt-3'>System Update in Progress</h2>
					{/* <p className='mb-1'>Make a cup of tea and pop back in a minute.</p> */}
					<p className='text-center'>
						We are currently releasing new updates to the system and should be finished shortly.
					</p>
				</Center>
			</CogLoader>
		</Center>
	)
}

const Center = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const UpcomingMaintenanceModalContent = ({ eta, period }) => {
	const _isMounted = React.useRef(false)
	const [timeRemaining, setTimeRemaining] = useSafeState('')

	React.useEffect(() => {
		_isMounted.current = true

		const timerInterval = setInterval(() => {
			if (_isMounted.current) {
				updateTimeRemaining()
			}
		}, 1000)

		return () => {
			clearInterval(timerInterval)
			_isMounted.current = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const updateTimeRemaining = () => {
		const now = moment()
		const maintenanceStartTime = moment(eta)
		const timeDiff = maintenanceStartTime - now

		if (timeDiff <= 0) {
			setTimeRemaining('starting now')
		} else {
			const duration = moment.duration(timeDiff)
			const hours = Math.floor(duration.asHours())
			const minutes = duration.minutes()

			if (hours === 0) {
				if (minutes === 1) {
					setTimeRemaining(`in 1 minute`)
				} else {
					setTimeRemaining(`in ${minutes} minutes`)
				}
			} else {
				if (hours === 1 && minutes === 0) {
					setTimeRemaining(`in 1 hour`)
				} else if (hours === 1) {
					setTimeRemaining(`in 1 hour and ${minutes} minutes`)
				} else if (minutes === 0) {
					setTimeRemaining(`in ${hours} hours`)
				} else {
					setTimeRemaining(`in ${hours} hours and ${minutes} minutes`)
				}
			}
		}
	}

	return (
		<>
			<div>
				<p className='mb-0'>
					This is a courtesy message that a system update will be deployed at{' '}
					<b>{moment(eta).format('HH:mm A')} (GMT)</b>
				</p>
				{timeRemaining && (
					<p>
						<b>({timeRemaining})</b>
					</p>
				)}
				<p>
					It is expected to last {period} during which time you will not be able to access the
					platform. We therefore suggest saving your work and resuming once the update has been
					completed. We apologise for any inconvenience.
				</p>
			</div>
		</>
	)
}
