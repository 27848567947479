/* Maintenance, App version & Build number check Using Response Interceptor */
import axios from 'axios'

import keys from '../config/keys'
import {
	initAppBuildKey,
	// initAppVersionKey,
	localBuildNumber,
	localVersionNumber,
} from '../config/vars'
import store from '../redux/store'
import { setCurrentData } from '../redux/actions/currentDataActions'

const { VERSION, BUILD } = keys

export default () => {
	// Interceptor for handling 503 responses
	axios.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			const statusCode = error?.response?.status
			// Check if the response status is 503
			if (statusCode === 503) {
				// Invoke the maintenance check function
				checkMaintenance()
			}
			return Promise.reject(error)
		}
	)

	// Interceptor for checking headers for all responses
	axios.interceptors.response.use(
		(response) => {
			// Run a specific function to check headers
			checkHeaders(response.headers)
			return response
		},
		(error) => {
			return Promise.reject(error)
		}
	)
}

// Function to check maintenance
function checkMaintenance() {
	// Implement your logic to handle maintenance here
	console.log('% Maintenance check triggered %')
	const el = document.getElementById(keys.maintenanceModalTriggerBtnId)
	el && el.click()
}

// Function to check headers
function checkHeaders(headers) {
	// Implement your logic to check headers here
	// console.log('Checking headers for app update:', headers)

	// const currentBuildNumber = window[keys.appBuildNumber]
	const currentBuildNumber = localBuildNumber
	const currentVersionNumber = localVersionNumber

	if (!currentVersionNumber) return

	const {
		timezone,
		build: newBuildNumber,
		version: newVersionNumber,
		maintenanceEta,
		maintenancePeriod,
		micrositeNodeDedicated,
	} = getAppStaticDataFromHeader(headers)

	window[keys.DEFAULT_TIMEZONE] = timezone
	window[keys.MICROSITE_NODE_DEDICATED] = micrositeNodeDedicated

	if (maintenanceEta) {
		store.dispatch(setCurrentData({ maintenanceEta }))
		window[keys.appMaintenanceEtaKey] = maintenanceEta
		window[keys.appMaintenancePeriodKey] = maintenancePeriod

		const lastOne = localStorage.getItem(keys.upcomingMaintenanceModalClosedFor)

		if (lastOne && lastOne === maintenanceEta) {
			// User already seen it and pressed ok
		} else {
			const el = document.getElementById(keys.upcomingMaintenanceModalTriggerBtnId)
			el && el.click()
		}
	}

	const checkedVersion = window[keys.versionUpdateCheckedKey]
	const checkedBuild = window[keys.buildUpdateCheckedKey]
	const lastChecked = window[keys.lastUpdateCheckTimestamp]

	const openPopup = () => {
		const el = document.getElementById(keys.updateModalTriggerBtnId)
		el && el.click()
	}

	const getOkayStatus = (to) => {
		const checkTimestamp = () => {
			let okay = false // User ignored this update already, so check if 5min passed
			if (lastChecked) {
				const currentTimestamp = new Date().valueOf()
				const FIVE_MIN = 5 * 60 * 1000
				if (currentTimestamp - Number(lastChecked) > FIVE_MIN) {
					okay = true // if 5min passed check popup again
				}
			}
			return okay
		}

		let okay = true

		if (to === VERSION) {
			if (newVersionNumber === checkedVersion) {
				okay = checkTimestamp()
			}
		} else if (to === BUILD) {
			if (newBuildNumber === checkedBuild) {
				okay = checkTimestamp()
			}
		}

		return okay
	}

	// if (
	// 	newVersionNumber !==
	// 	(sessionStorage.getItem(initAppVersionKey) || currentVersionNumber.toString())
	// ) {
	// 	const okay = getOkayStatus(VERSION)

	// 	if (okay) {
	// 		window[keys.appUpdateTypeKey] = VERSION
	// 		window[keys.versionProcessingKey] = newVersionNumber // Just for data passing
	// 		sessionStorage.setItem(initAppVersionKey, newVersionNumber)
	// 		openPopup()
	// 	}
	// } else

	if (
		Number(newBuildNumber) >
		Number(sessionStorage.getItem(initAppBuildKey) || currentBuildNumber.toString())
	) {
		const okay = getOkayStatus(BUILD)

		if (okay) {
			window[keys.appUpdateTypeKey] = BUILD
			window[keys.buildProcessingKey] = newBuildNumber // Just for data passing
			sessionStorage.setItem(initAppBuildKey, newBuildNumber)
			openPopup()
		}
	}

	if (newBuildNumber === currentBuildNumber.toString()) {
		sessionStorage.removeItem(initAppBuildKey)
	}

	// if (newVersionNumber === currentVersionNumber.toString()) {
	// 	sessionStorage.removeItem(initAppVersionKey)
	// }
}

export const getAppStaticDataFromHeader = (_headers) => {
	const headers = _headers ?? {}
	return {
		timezone: headers['X-Api-Timezone'] ?? headers['x-api-timezone'],
		build: headers['X-Api-App-Build'] ?? headers['x-api-app-build'],
		version: headers['X-Api-App-Version'] ?? headers['x-api-app-version'],
		maintenanceEta: headers['X-Api-App-Maintenance-Eta'] ?? headers['x-api-app-maintenance-eta'],
		maintenancePeriod:
			headers['X-Api-App-Maintenance-Period'] ?? headers['x-api-app-maintenance-period'],
		micrositeNodeDedicated:
			headers['X-Emma-Microsite-Node-Dedicated'] ?? headers['x-emma-microsite-node-dedicated'],
	}
}
