import { combineReducers } from 'redux'

import themeReducer from './themeReducer'
import currentDataReducer from './currentDataReducer'
import authReducer from './authReducer'
import settingsReducer from './settingsReducer'
import voucherFilterReducer from './voucherFilterReducer'
import viewlistStateReducer from './viewlistStateReducer' // Later introduced

export default combineReducers({
	auth: authReducer,
	settings: settingsReducer,
	theme: themeReducer,
	currentData: currentDataReducer,
	voucherGlobalFilter: voucherFilterReducer,
	viewlistState: viewlistStateReducer,
})
