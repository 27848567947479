import Axios from 'axios'
import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
// import Axios from 'axios'
import {
	CopyOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
	QuestionCircleOutlined,
	RightOutlined,
	SyncOutlined,
	TagsOutlined,
	WarningOutlined,
} from '@ant-design/icons'
import { IoMdCloseCircle } from 'react-icons/io'
import styled from 'styled-components'
import { useSafeState } from 'ahooks'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
	Col,
	Row,
	Collapse,
	Divider,
	Modal,
	Button,
	// Spin,
	Table,
	Popover,
	Tooltip,
	message,
	Space,
} from 'antd'

import { _getPopupContainer, chevronBottom, chevronTop } from '../utils/helpers'
// import keys from '../config/keys'
// import vars from '../config/vars'
// import endpoints from '../config/endpoints'
// import handleError from '../utils/handleError'
import isEmpty from '../utils/isEmpty'
import endpoints from '../config/endpoints'
import handleError from '../utils/handleError'

export const SimpleRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: ${(props) => (props.isLoading ? 'progress' : 'auto')};
	pointer-events: ${(props) => (props.isLoading ? 'none' : 'auto')};
	opacity: ${(props) => (props.isLoading ? 0.5 : 1)};
`

export const PrefixLoading = styled(LoadingOutlined)`
	padding: 8px;
	border: 1px solid var(--border-color);
	border-right: none;
	border-bottom-left-radius: 2px;
	border-top-left-radius: 2px !important;
	background: #fff;
`

export const emmaModalCloseIcon = <IoMdCloseCircle size={24} color='#fff' />

export const CollapsePanel = ({
	children,
	className = 'mb-2',
	name = 'Panel',
	defaultActivePanel = 'Event',
}) => {
	const [isActive, setIsActive] = React.useState(name === defaultActivePanel)
	return (
		<div style={{ position: 'relative' }} className={className}>
			<Collapse
				ghost
				destroyInactivePanel
				className='summary-tab-collapse'
				defaultActiveKey={defaultActivePanel}
				onChange={() => setIsActive((prevVal) => !prevVal)}
			>
				<Collapse.Panel
					key={name}
					showArrow={false}
					header={
						<>
							<Row justify='space-between' align='middle'>
								<Col>
									<h4 id='section-title' className='emma-color m-0'>
										{name}
									</h4>
								</Col>
								<Col className='d-flex align-items-center'>
									{isActive ? chevronTop : chevronBottom}
								</Col>
							</Row>
							<Divider className='mb-0 mt-3' />
						</>
					}
				>
					{children}
				</Collapse.Panel>
			</Collapse>
		</div>
	)
}

export function ContentFormattingTagsPreview({ btnStyle = {} }) {
	const _isMounted = React.useRef(false)
	const [modal, setModal] = useState(false)
	// const [loading, setLoading] = useState(true)
	// const [tagsList, setTagsList] = useState([])
	const tagsList = [
		{ title: 'Total Raised', tag: '(#total_raised#)' },
		{ title: 'Public Domain Content', tag: '(#public_domain_content#)' },
		{ title: 'Donation Form', tag: '(#donation_form#)' },
	]

	// const getTagsData = async () => {
	// 	try {
	// 		_isMounted.current && setLoading(true)
	// 		const res = await Axios.get(
	// 			endpoints.getEndpointWithSuffix(
	// 				`/module/bb_emma_events/${eventId}/comm-tags?template_type=${template_type}`
	// 			)
	// 		)
	// 		const resData = res.data || {}
	// 		console.log('Tags data:', resData)
	// 		_isMounted.current && setTagsList(resData)
	// 	} catch (error) {
	// 		handleError(error, true)
	// 	} finally {
	// 		_isMounted.current && setLoading(false)
	// 	}
	// }

	// useEffect(() => {
	// 	if (_isMounted.current && modal === true) {
	// 		getTagsData()
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [modal])

	useEffect(() => {
		_isMounted.current = true
		return () => {
			_isMounted.current = false
		}
	}, [])

	const tagsBtn = (
		<Button
			type='default'
			icon={<TagsOutlined rotate={270} style={{ marginRight: -4, marginTop: 2 }} />}
			loading={false}
			onClick={() => setModal(true)}
			// style={hidden ? vars.hiddenStyles : {}}
			style={{ ...btnStyle }}
		>
			Tags
		</Button>
	)

	const columns = [
		{ dataIndex: 'title', key: 'title' },
		{ dataIndex: 'tag', key: 'tag' },
		{ dataIndex: 'action', key: 'action' },
	]
	const dataSource = tagsList.map((x, i) => ({ __serial: i, ...x }))

	return (
		<>
			{tagsBtn}
			<Modal
				destroyOnClose
				closeIcon={emmaModalCloseIcon}
				className='emma-modal ant-modal-width-mid'
				title='Tags'
				visible={modal}
				onCancel={() => setModal(false)}
				style={{ top: 40 }}
				bodyStyle={{ height: '85vh', overflow: 'scroll' }}
				footer={null}
			>
				{/* {loading && (
					<Row justify='center'>
						<Spin />
					</Row>
				)} */}
				{!isEmpty(tagsList) && (
					<div style={{ paddingLeft: 12, paddingRight: 12 }}>
						<Table
							size='small'
							rowKey='__serial'
							dataSource={dataSource}
							columns={columns.map((col) => {
								if (col.dataIndex === 'title') {
									return {
										...col,
										render: (text, record) => {
											return (
												<Row align='middle'>
													{text}
													{record.tooltip && (
														<Popover
															placement='right'
															content={record.tooltip}
															getPopupContainer={_getPopupContainer}
														>
															<InfoCircleOutlined className='ml-2' />
														</Popover>
													)}
												</Row>
											)
										},
									}
								} else if (col.dataIndex === 'action') {
									return {
										...col,
										align: 'center',
										render: (_, record) => {
											return (
												<Tooltip
													title='Copy to clipboard'
													placement='left'
													getPopupContainer={_getPopupContainer}
												>
													<CopyToClipboard
														text={record.tag}
														onCopy={() => message.success(`Copied '${record.tag}'!`)}
													>
														<CopyOutlined style={{ cursor: 'pointer' }} rotate={-180} />
													</CopyToClipboard>
												</Tooltip>
											)
										},
									}
								}
								return col
							})}
							showHeader={false}
							pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
						/>
					</div>
				)}
			</Modal>
		</>
	)
}

export const HiddenButton = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: -9;
	height: 0;
	width: 0;
	opacity: 0;
`

export const _bottomSubmitRowStyles = {
	margin: 0,
	position: 'absolute',
	bottom: 0,
	width: '100%',
	left: 0,
	background: '#fff',
	padding: '10px 16px',
	borderTop: '1px solid #ddd',
	zIndex: 9,
}

export const centerClass = 'd-flex justify-content-center align-items-center'

const warnIcon = <WarningOutlined style={{ fontSize: 20, color: 'red' }} />
export const makeWinnerWarnCommonProps = {
	maskClosable: true,
	className: 'emma-popup-confirm-modal',
	title: <b>Are you sure?</b>,
	icon: <QuestionCircleOutlined />,
	content: (
		<>
			<p>An item you have selected is not the highest bid on this auction item.</p>
			<p>
				Click 'Cancel' to return to the page and amend your selection or click 'Continue' to make
				them the winner.
			</p>
		</>
	),
	okText: (
		<>
			Continue <RightOutlined />
		</>
	),
	cancelText: 'Cancel',
}
export const bidUnderReserveWarnCommonProps = {
	...makeWinnerWarnCommonProps,
	title: <b>Bid Under Reserve</b>,
	content: (
		<>
			<p>An item you have selected has a bid under the reserve.</p>
			<p>
				To continue please click 'Continue' otherwise click 'Cancel' to return to the page and
				unselect the items(s) that will be highlighted in red.
			</p>
		</>
	),
	icon: warnIcon,
}
export const liveItemSelectedWarnCommonProps = {
	...makeWinnerWarnCommonProps,
	title: <b>Live Item Selected</b>,
	content: (
		<>
			<p>An item you have selected is a Live Auction item.</p>
			<p>
				To continue please click 'Continue' otherwise click 'Cancel' to return to the page and
				unselect the Live item(s) in question.
			</p>
		</>
	),
	icon: warnIcon,
}

export const handleMakeWinner = ({
	rowId,
	qty_availableNUM,
	winning_bidYN,
	__position,
	callback,
	previousBidItem,
}) => {
	let showWarn = true

	if (__position === 0) {
		// No action - As height bid
		showWarn = false
	} else {
		if (qty_availableNUM && previousBidItem && __position <= qty_availableNUM - 1) {
			for (let count = 1; count < qty_availableNUM; count++) {
				if (__position === count) {
					if (previousBidItem.winning_bidYN) {
						// No action - As next height bid
						showWarn = false
					} else {
						showWarn = true
					}
				}
			}
		}
	}

	if (showWarn) {
		Modal.confirm({
			...makeWinnerWarnCommonProps,
			onOk: (close) => {
				close()
				callback && callback()
			},
		})
	} else {
		callback && callback()
	}
}

export const RefreshLeaderboard = () => {
	const screens = useBreakpoint()
	const { id: eventId } = useParams()
	const [loading, setLoading] = useSafeState(false)

	const onClick = async () => {
		try {
			setLoading(true)
			const endpoint = endpoints.getEndpointWithSuffix(
				`/module/bb_emma_events/${eventId}/pusher/leaderboard-settings-update`
			)
			const { data } = await Axios.post(endpoint, { leaderboard_refresh: true })
			console.log('Refresh Leaderboard Res:', data)
			message.success(`Action Successful!`)
		} catch (error) {
			handleError(error, true)
		} finally {
			setLoading(false)
		}
	}

	return (
		<>
			<Button
				type='primary'
				loading={loading}
				icon={<SyncOutlined />}
				className='yellow d-flex align-items-center'
				style={{}}
				onClick={onClick}
			>
				{screens?.xs === false ? 'Refresh Leaderboard' : ''}
			</Button>
		</>
	)
}

export const ToggleButton = ({
	initialVal = false,
	icon,
	propertyKey,
	prefixIcon,
	endpoint,
	activeLabel = 'Button',
	inactiveLabel = 'Button',
	size = 'small',
	overridePostVal,
}) => {
	const screens = useBreakpoint()
	const [val, setVal] = useSafeState(initialVal)
	const [loading, setLoading] = useSafeState(false)

	const onClick = async () => {
		try {
			setLoading(true)
			const newVal = overridePostVal ?? !val
			const { data } = await Axios.patch(endpoint, { [propertyKey]: newVal })
			setVal(newVal)
			console.log('Update Res:', data)
			message.success(`Action Successful!`)
		} catch (error) {
			handleError(error, true)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Row justify='center' align='middle'>
			<Button
				size={size}
				type='primary'
				loading={loading}
				icon={icon}
				className='d-flex align-items-center'
				onClick={onClick}
			>
				{!loading && <>{prefixIcon}</>}
				{screens?.xs === false ? (val ? inactiveLabel : activeLabel) : ''}
			</Button>
		</Row>
	)
}

export const refundConfirmTitle = (
	<Space direction='vertical' size={`small`}>
		<p className='m-0'>Are you sure you want to mark this payment as refunded?</p>
		<p className='m-0 font-weight-light'>
			To process the refund it will then need to be done directly from your Stripe account or via a
			bank transfer from your bank account. We recommend via bank transfer to save on Stripe
			processing fees.
		</p>
	</Space>
)

export const RefundSpecificWarn = (
	<Row align='middle'>
		<Col className='d-flex justify-content-center align-items-center'>
			<i className='cil-warning emma-color' />
			&nbsp;
		</Col>
		<Col>
			<p className='m-0'>
				To process the refund it will then need to be done directly from your Stripe account or via
				a bank transfer from your bank account. We recommend via bank transfer to save on Stripe
				processing fees.
			</p>
		</Col>
	</Row>
)
