import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const PrivateRoute = ({ component: Component, auth, appSettings, ...rest }) => {
	const { login_url } = appSettings || {}
	if (auth.isAuthenticated === false && login_url) {
		window.location.href = login_url
		return null
	}
	return (
		<Route
			{...rest}
			render={(props) =>
				auth.isAuthenticated === true ? <Component {...props} /> : <Redirect to='/login' />
			}
		/>
	)
}

PrivateRoute.propTypes = {
	auth: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({ auth: state.auth, appSettings: state.settings.settings })

export default connect(mapStateToProps, null)(PrivateRoute)
