import Axios from 'axios'
import moment from 'moment'
import domtoimage from 'dom-to-image-more'

import keys from '../config/keys'
import endpoints from '../config/endpoints'
import { localBuildNumber, localVersionNumber } from '../config/vars'
import { cleanBase64ForAjax, getFileObjForAjax, getSizeFromBase64 } from './fileHelpers'
import { ISO_8601_FORMAT } from './timeHelpers'

const asyncLogError = async ({ error, pd = {}, title, desc }) => {
	let success
	let postData

	const http_code = error?.response?.status
	// if ([401, 503].includes(http_code)) return

	try {
		const screenshotExt = `jpeg`
		const screenshotType = `image/${screenshotExt}`
		const element = document.body
		const screenshotBase64 = await domtoimage.toJpeg(element, {
			quality: 0.5,
			filter: (node) => !['NOSCRIPT'].includes(node.tagName),
		})

		// console.log(screenshotBase64)

		postData = {
			app_urlISsmallplaintextbox: window.location.href,
			urlISsmallplaintextbox: error?.config?.url || window.location.href,
			http_code,
			app_version: localVersionNumber,
			app_buildNUM: localBuildNumber,
			microsite_server_node: localStorage.getItem(keys.EMMA_MICROSITE_SERVER_NODE_ID),
			datetime: moment().format(ISO_8601_FORMAT),
			errorISsmallplaintextbox: (title || 'Error') + (desc ? ` - ${desc}` : ''),
			detailsISplaintextbox: JSON.stringify(error?.response ?? error),
			screenshotISfile: getFileObjForAjax(
				{
					name: document.title + ` - Screenshot.${screenshotExt}`,
					size: getSizeFromBase64(cleanBase64ForAjax(screenshotBase64, screenshotType)),
					type: screenshotType,
				},
				screenshotBase64
			),
			...pd,
		}

		Axios.post(endpoints.log_error, postData)
		success = true
	} catch (err) {
		console.log('[Failed to log the error] ->', err)
		success = false
	}

	return { success, postData }
}

export default asyncLogError
