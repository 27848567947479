import { SET_THEME_DATA } from '../actions/types'

const initialState = {
	asideShow: false,
	darkMode: false,
	showFooter: true,
	headerShow: true,
	subHeaderShow: true,
	sidebarShow: 'responsive',
	contentFullHeight: false,
	sidebarClass: 'c-sidebar-fixed c-sidebar-unfoldable', // ''
	pageLayout: null,
	contentLayout: null,
	currentModule: '',
	currentPage: '',
	currentDataID: '',
	currentTitle: '',
	viewEditContainerClass: '',
	hovering: false,
}

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_THEME_DATA:
			return { ...state, ...action.payload }
		default:
			return state
	}
}
