// import WebFont from 'webfontloader'

import isEmpty from './isEmpty'

const root = document.documentElement
const _set = (key, val) => key && val && root.style.setProperty(key, val)

export const updateThemeViaCssVar = (theme) => {
	if (isEmpty(theme)) return

	_set('--primary-color', theme.primary_color)
	_set('--secondary-color', theme.secondary_color)
	_set('--body-bg-color', theme.background_color)

	// if (theme.font) {
	// 	_set('--main-font', `${theme.font}, sans-serif`)
	// 	loadFont(theme.font)
	// }
}

// export const loadFont = (fontName) => {
// 	WebFont.load({
// 		google: {
// 			families: [`${fontName}:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i`],
// 		},
// 	})
// }
