import 'react-app-polyfill/ie11' // For IE 11 support
import 'react-app-polyfill/stable'
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import HttpsRedirect from 'react-https-redirect'
import * as serviceWorker from './serviceWorker'
import 'antd/dist/antd.css'

import App from './App'
import keys from './config/keys'
import { icons } from './assets/icons'
import Maintenance from './views/Maintenance'
import { getQueryVariable } from './utils/helpers'
import axiosReqInterceptor from './utils/axiosReqInterceptor'
import axiosResInterceptor from './utils/axiosResInterceptor'
import { setAxiosDefaultHeader } from './utils/setAxiosDefaultHeader'

// Capture the initial req route
const initReqRoute = window.location.pathname + window.location.search
console.log('Init page req:', initReqRoute)
window[keys.redirect] = initReqRoute
const apikey = getQueryVariable('user_apikey')
if (apikey) {
	localStorage.setItem(keys.TEMP_API_KEY, apikey)
} else {
	localStorage.removeItem(keys.TEMP_API_KEY)
}

// Clear the deprecated update related local vars
localStorage.removeItem(keys.lastUpdateCheckTimestamp)
localStorage.removeItem(keys.versionUpdateCheckedKey)
localStorage.removeItem(keys.versionProcessingKey)
localStorage.removeItem(keys.appVersion)

setAxiosDefaultHeader(true)
// Activate 'Refresh Token Strategy', Update checker
axiosReqInterceptor()
axiosResInterceptor()

React.icons = icons

ReactDOM.render(
	<HttpsRedirect>
		<>
			<App />
			{/* Maintenance Related */}
			<Maintenance />
		</>
	</HttpsRedirect>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
