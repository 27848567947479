import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

export default function TheBody() {
	const settings = useSelector((state) => state.settings?.settings)
	const user = useSelector((state) => state.auth?.user)
	const { logos } = user?.ui_settings || {}
	const { favicon_16ISfile, favicon_32ISfile, favicon_192ISfile } = settings || {}

	const favicon = logos?.faviconISfile

	return (
		<>
			<Helmet>
				{favicon_16ISfile?.uri && (
					<link
						rel='icon'
						type={favicon_16ISfile.type || 'image/png'}
						href={favicon?.uri || favicon_16ISfile.uri}
						sizes='16x16'
					/>
				)}
				{favicon_32ISfile?.uri && (
					<link
						rel='icon'
						type={favicon_32ISfile.type || 'image/png'}
						href={favicon?.uri || favicon_32ISfile.uri}
						sizes='32x32'
					/>
				)}
				{favicon_192ISfile?.uri && (
					<link
						rel='icon'
						type={favicon_192ISfile.type || 'image/png'}
						href={favicon?.uri || favicon_192ISfile.uri}
						sizes='192x192'
					/>
				)}
				{favicon?.uri && (
					<link rel='icon' type={favicon.type || 'image/png'} href={favicon.uri} sizes='192x192' />
				)}
				{favicon?.uri && user?.user?.org && <title>{user?.user?.org}</title>}
				{favicon?.uri && user?.user?.org && <meta name='description' content={user?.user?.org} />}
			</Helmet>
		</>
	)
}
