export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export const SET_SETTINGS = 'SET_SETTINGS'
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'

export const UPDATE_VOUCHER_FILTERS = 'UPDATE_VOUCHER_FILTERS'

export const SET_THEME_DATA = 'SET_THEME_DATA'

export const SET_CURRENT_DATA = 'SET_CURRENT_DATA'

export const UPDATE_VIEWLIST_STATE = 'UPDATE_VIEWLIST_STATE'
export const SET_VIEWLIST_LOADING_STATE = 'SET_VIEWLIST_LOADING_STATE'
export const RESET_VIEWLIST_STATE = 'RESET_VIEWLIST_STATE'
