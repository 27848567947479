import React from 'react'

import SomethingWrong from './SomethingWrong'
import asyncLogError from '../utils/asyncLogError'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		// Log the error to an error reporting service
		this.logErrorToMyService(error, errorInfo)
	}

	logErrorToMyService = (error, errorInfo) => {
		console.log(error, errorInfo)
		asyncLogError({ error: errorInfo, title: 'Frontend - JS Error', desc: `${error}` })
	}

	render() {
		if (this.state.hasError) {
			// Render any custom fallback UI
			return <SomethingWrong />
		}

		return this.props.children
	}
}

export default ErrorBoundary
