import axios from 'axios'

const setHeaderAccessToken = (token) => {
	if (token) {
		// Apply to every request
		console.log('Setting up "Authorization" header to Axios!')
		axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
	} else {
		// Delete auth header
		console.log('Deleting "Authorization" header to Axios!')
		delete axios.defaults.headers.common['Authorization']
	}
}

export default setHeaderAccessToken
