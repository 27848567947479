// import keys from './keys'
import store from '../redux/store'
import { getAuthState, generateCodeChallenge } from '../utils/helpers'
import keys from './keys'

const devEnv = ['localhost', 'netlify.app', 'ngrok.io']
const HOST = window.location.origin

export const isDevEnv = devEnv.some((x) =>
	HOST.trim().toLocaleLowerCase().includes(x.trim().toLocaleLowerCase())
)
const apiBaseUrl = isDevEnv ? 'https://emma-system-dev.blueboxonline.com' : HOST
console.log({ apiBaseUrl })
const apiVersion = 'v1'

export default {
	ping: `${apiBaseUrl}/api/${apiVersion}/ping`,
	status: `${apiBaseUrl}/api/${apiVersion}/app/status`,
	settings: `${apiBaseUrl}/api/${apiVersion}/app/settings`,
	login_requirements: `${apiBaseUrl}/api/${apiVersion}/users/login-requirements`,
	login: `${apiBaseUrl}/api/${apiVersion}/users/login`,
	otp: `${apiBaseUrl}/api/${apiVersion}/users/otp`,
	otp_resend: `${apiBaseUrl}/api/${apiVersion}/users/otp/resend`,
	temp_login: `${apiBaseUrl}/api/${apiVersion}/users/login-apikey`,
	forgotPassword: `${apiBaseUrl}/api/${apiVersion}/users/forgot-password`,
	resetPassword: `${apiBaseUrl}/api/${apiVersion}/users/reset-password`,
	logout: `${apiBaseUrl}/api/${apiVersion}/users/logout`,
	log_error: `https://logs.emma-live.com/api/v1/system/error-log`,
	getCatalogueItemTypes: `${apiBaseUrl}/api/${apiVersion}/module/bb_emma_voucher_types`,
	getPageSchemaEndpoint: (routeKey) => `${apiBaseUrl}/api/${apiVersion}/pages/${routeKey}`,
	getDeleteFileEndpoint: (module_name, id, file_field) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}/${id}/files/${file_field}`,
	getDeleteProfileFileEndpoint: (file_field) =>
		`${apiBaseUrl}/api/${apiVersion}/users/profile/files/${file_field}`,
	liveUpcomingEventsEndpoint: `${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events?status=live_upcoming`,
	getLiveEventSaveEndpoint: (module_name, catalogue_id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}/${catalogue_id}/add-to-event`,
	lotsEndpoint: `${apiBaseUrl}/api/${apiVersion}/module/bb_emma_lots`,
	getRemoveLotItemEndpoint: (id) => `${apiBaseUrl}/api/${apiVersion}/module/bb_emma_lots/${id}`,
	getVoucherCancelEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_vouchers/${id}/cancel`,
	getVoucherConfirmEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_vouchers/${id}/confirm`,
	getUploadInvoiceEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_vouchers/${id}/supplier-invoice`,
	getConfirmPaidEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_vouchers/${id}/supplier-payment`,
	getCapturePaymentEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${id}/guest-payment`,
	getBulkCapturePaymentEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${id}/guest-payment/batch`,
	getCapturePaymentSendPayLinkEndpoint: (eventId, guestId) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${eventId}/guests/${guestId}/pay-message`,
	getNewMessageEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_vouchers/${id}/message`,
	getResendMessageEndpoint: (id, logId) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_vouchers/${id}/resend-message/${logId}`,
	getDefaultCommunicationTemplateEndpoint: (id, type) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${id}/default-comm-template?type=${type}`,
	getCommunicationTemplatePreviewEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${id}/preview-comm-template`,
	getCommunicationTemplateAddEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${id}/comm-template`,
	getCommunicationTemplateUpdateEndpoint: (id, templateId) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${id}/comm-template/${templateId}`,
	getEventCommunicationSchemaEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${id}/comm-log`,
	getEventCommunicationLogEndpoint: (id, template_type, status) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${id}/comm-log${
			template_type ? `?template_type=${template_type}` : ''
		}${status ? `${template_type ? '&' : '?'}status=${status}` : ''}`,
	getEventCommunicationLogResendEndpoint: (id, logId) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${id}/resend-message/${logId}`,

	userProfile: `${apiBaseUrl}/api/${apiVersion}/users/profile`,
	microSiteServerNodes: `${apiBaseUrl}/api/${apiVersion}/module/bb_emma_microsite_nodes`,
	microSiteActiveNode: `${apiBaseUrl}/api/${apiVersion}/microsites-proxy/active-node`,
	microSiteSetActiveNode: `${apiBaseUrl}/api/${apiVersion}/microsites-proxy/active-node`,
	getModuleSchemaEndpoint: (module_name, action, id) =>
		`${apiBaseUrl}/api/${apiVersion}/schemas/${module_name}/${action}${id ? `?id=${id}` : ''}`,
	getModuleDataEndpoint: (module_name) => `${apiBaseUrl}/api/${apiVersion}/module/${module_name}`,
	getDataAddEndpoint: (module_name) => `${apiBaseUrl}/api/${apiVersion}/module/${module_name}`,
	getDataItemTabViewEndpoint: (suffix, id) =>
		`${apiBaseUrl}/api/${apiVersion}/${suffix.replace(':id', id)}`,
	getDataItemViewEndpoint: (module_name, id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}${id ? `/${id}` : ''}`,
	getDataItemEditEndpoint: (module_name, id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}/${id}`,
	getDataItemDeleteEndpoint: (module_name, id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}/${id}`,
	getAuthEndpoint: () =>
		`${apiBaseUrl}/api/${apiVersion}/oauth/auth?response_type=code&client_id=${
			store.getState().settings?.settings?.oauth_clientISbb_oauth_clientsID
		}&state=${getAuthState()}&code_challenge=${generateCodeChallenge()}&code_challenge_method=S256&redirect_uri=${encodeURIComponent(
			window.location.protocol + '//' + window.location.host + window.location.pathname
		)}`,
	authTokenEndpoint: `${apiBaseUrl}/api/${apiVersion}/oauth/token`,
	authRevokeEndpoint: `${apiBaseUrl}/api/${apiVersion}/oauth/revoke`,
	getLookupEndpoint: (suffix) => {
		const EMMA_MICROSITE_NODE_ID = localStorage.getItem(keys.EMMA_MICROSITE_NODE_ID)
		return `${apiBaseUrl}/api/${apiVersion}${suffix}`.replace(':node-id', EMMA_MICROSITE_NODE_ID)
	},
	getDataBulkDeleteEndpoint: (module_name) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}`,
	getAddSelectedEndpoint: (cat_id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_catalogue_items/${cat_id}/add-to-event`,
	getAddBulkSelectedEndpoint: (eventId) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${eventId}/add-cat-items`,
	getDropTableEndpoint: (module_name) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}/table`,
	getDropColumnEndpoint: (module_name) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}/columns`,
	getModuleSettingsEndpoint: (module_name) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}/settings`,
	getModuleSettingUpdateEndpoint: (module_name, setting) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}/settings/${setting}`,
	getDefaultDataEndpoint: (module_name) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}/table/default-data`,
	notificationsReadEndpoint: `${apiBaseUrl}/api/${apiVersion}/users/notifications/read`,
	getNotificationClickEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/users/notifications/${id}/read`,
	getMoreNotificationEndpoint: (offset = 5) =>
		`${apiBaseUrl}/api/${apiVersion}/users/notifications?offset=${offset}`,
	guestBulkCommonEndpoint: `${apiBaseUrl}/api/${apiVersion}/module/bb_emma_guests/batch`,
	getGuestCheckInOrOutEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_guests/${id}`,
	getGuestInviteEndpoint: (eventId, guestId) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${eventId}/guests/${guestId}/invite`,
	getGuestBulkInviteEndpoint: (eventId) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${eventId}/guests/bulk-invite`,
	getEventQuestionsEndpoint: (eventId) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${eventId}/questions`,
	getGuestPrintIdEndpoint: (eventId, guestId) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${eventId}/guests/${guestId}/id-html`,
	getGuestBulkPrintIdEndpoint: (eventId) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${eventId}/guests/bulk-id-html`,
	getVoucherPageSuperGlobalSearchEndpoint: (query) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/search?type=input&module=bb_emma_vouchers&field=event_idNUM&query=${query}`,
	getEndpointWithSuffix: (suffix) => `${apiBaseUrl}/api/${apiVersion}${suffix}`,
	getEventReportsEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_events/${id}/reports`,
	getVirtualChannelEndpoint: (id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_virtual_channels/${id}/manage`,
	getChannelTemplateSaveEndpoint: (chan_id, template_id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_virtual_channels/${chan_id}/manage/templates/${template_id}`,
	getChannelElementEndpoint: (chan_id, element_id) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_virtual_channels/${chan_id}/manage/elements/${element_id}`,
	internalLinksEndpoint: `${apiBaseUrl}/api/${apiVersion}/module/bb_emma_content/internal-links`,
	contentBatchEndpoint: `${apiBaseUrl}/api/${apiVersion}/module/bb_emma_event_content/batch`,
	lotsBatchEndpoint: `${apiBaseUrl}/api/${apiVersion}/module/bb_emma_lots/batch`,
	pledgesBatchEndpoint: `${apiBaseUrl}/api/${apiVersion}/module/bb_emma_pledges/batch`,
	getBatchEndpointForSort: (module_name) =>
		`${apiBaseUrl}/api/${apiVersion}/module/${module_name}/batch`,
	fontAwesomeIcons: `${apiBaseUrl}/api/${apiVersion}/fontawesome-icon-list`,
	getChannelTemplatePanelClearEndpoint: (template_id, panel_number) =>
		`${apiBaseUrl}/api/${apiVersion}/module/bb_emma_virtual_channels_templates/${template_id}/clear-panel/${panel_number}`,
	getCataloguesEndpointWithSuffix: (template = 'emma_catalogue_items_list_viewlist') =>
		`${apiBaseUrl}/api/${apiVersion}/schemas/bb_emma_catalogue_items/viewlist?template=${template}`,
}
