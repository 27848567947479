import React from 'react'

import Loading from './index'
import vars from '../../config/vars'
import keys from '../../config/keys'

/* props can be -> color, msg, msgColor, opacity */
function SplashScreenLoading(props) {
	const API_LOGO = localStorage.getItem(keys.SPLASH_LOGO)
	const BG_COLOR = localStorage.getItem(keys.SPLASH_BG_COLOR) || '#fff'

	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				height: '100vh',
				width: '100vw',
				scroll: 'none',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				zIndex: 999999,
				backgroundColor: BG_COLOR,
			}}
		>
			{API_LOGO && <img className='mb-2' src={API_LOGO} height={100} alt='EMMA' />}
			<Loading {...props} style={{ marginTop: 0 }} msgColor={vars.emmaColor} />
		</div>
	)
}

export default SplashScreenLoading
