import React from 'react'
import { Result, Button, Row, Space } from 'antd'

import EmmaHeroImage from '../assets/emma-hero-shadow.svg'
import { localBuildNumber, localVersionNumber } from '../config/vars'

function SomethingWrong({ title, subTitle, onClick, height, iconStyles = {} }) {
	return (
		<Row justify='center' align='middle' style={{ minHeight: height ?? '100vh' }}>
			<Result
				title={title ?? '500'}
				icon={
					<img src={EmmaHeroImage} alt='Something is wrong!' width='220px' style={iconStyles} />
				}
				subTitle={subTitle ?? `Sorry, Something went wrong. Please try again.`}
				extra={
					<Space direction='vertical'>
						<Button
							type='dashed'
							onClick={onClick ? () => onClick() : () => window.location.reload()}
						>
							<b>Try Again</b>
						</Button>

						<span className='text-center ant-result-subtitle'>
							v{localVersionNumber || ''}&nbsp; (Build {localBuildNumber})
						</span>
					</Space>
				}
			/>
		</Row>
	)
}

export default React.memo(SomethingWrong)
