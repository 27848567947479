import React, { Component } from 'react'
import axios from 'axios'
// import aes from 'crypto-js/aes'
import { Provider } from 'react-redux'
// import encUTF8 from 'crypto-js/enc-utf8'
import { createBrowserHistory } from 'history'
import { Offline, Online } from 'react-detect-offline'
import { Route, Router, Switch } from 'react-router-dom'

import './vars.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './scss/style.scss'
import './App.less'

import vars from './config/vars'
import keys from './config/keys'
import store from './redux/store'
import endpoints from './config/endpoints'
import handleError from './utils/handleError'
import PublicRoute from './utils/PublicRoute'
import PrivateRoute from './utils/PrivateRoute'
import OfflineNotice from './components/Offline'
import HelmetHeader from './containers/HelmetHeader'
import ErrorBoundary from './components/ErrorBoundary'
import { getAppSettings } from './utils/getAppSettings'
import { sleep, getQueryVariable } from './utils/helpers'
import { setCurrentUser } from './redux/actions/authActions'
import LoadingCenter from './components/Loading/LoadingCenter'
// import { setAccessToken } from './redux/actions/authActions'
// import setHeaderAccessToken from './utils/setHeaderAccessToken'
import SplashScreenLoading from './components/Loading/SplashScreenLoading'

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const OTP = React.lazy(() => import('./screens/OTP'))
const Login = React.lazy(() => import('./screens/login/Login'))
const ResetPassword = React.lazy(() => import('./screens/ResetPassword/ResetPassword'))
const ForgotPassword = React.lazy(() => import('./screens/ForgotPassword/ForgotPassword'))

export const appRouteHistory = createBrowserHistory()

const getPollingConf = (store) => {
	const { offline_pollingYN = true } = store.getState().settings?.settings
	return { enabled: offline_pollingYN, interval: 1000 * 1 * 60, timeout: 5000, url: endpoints.ping } // milliseconds
}

class App extends Component {
	constructor(props) {
		super(props)
		this.state = { loading: true, checking: true, error: '' }
	}

	async componentDidMount() {
		this.mounted = true

		/* Get the height of the browser's UI elements */
		const browserUIHeight = window.outerHeight - window.innerHeight
		/* Get the actual viewport height */
		const actualViewportHeight =
			window.visualViewport?.height || window.innerHeight - browserUIHeight
		/* Set the value of a CSS variable */
		document.documentElement.style.setProperty(
			'--actual-viewport-height',
			`${actualViewportHeight}px`
		)

		try {
			await sleep(2500) // For splash feel
			this.mounted && this.setState({ loading: true })
			const settingsData = await getAppSettings()

			const redirect = window[keys.redirect]
			this.updateRedirectAfterLoginVal(redirect)

			await this.checkUserSession(settingsData)

			// performSessionLogic(settingsRes, redirect) // If needed

			this.mounted && this.setState({ loading: false })
		} catch (error) {
			const statusCode = error?.response?.status
			handleError(error, true)
			this.mounted &&
				this.setState({
					error: `Something went wrong${
						statusCode ? ` (${statusCode})` : ''
					}. Please refresh & try again!`,
				})
			// localStorage.removeItem(keys.accessToken)
		}
	}

	checkUserSession = async (settings = {}) => {
		try {
			this.mounted && this.setState({ checking: true })
			const userProfileRes = await axios.get(endpoints.userProfile)
			const user = userProfileRes.data
			console.log('App User Profile: ', user)
			store.dispatch(setCurrentUser(user))
			this.mounted && this.setState({ checking: false })

			const isDebugChecked = getQueryVariable('debug') || ''
			// Disable console.log in prod
			console.log('NODE_ENV:', process.env.NODE_ENV)
			if (process.env.NODE_ENV === 'production') {
				if (user?.permissions?.admin === true) return // Admin can see
				// Else have to put ?debug=yes|1 (any value)
				if (!isDebugChecked) {
					console.log = () => {}
				}
			}
		} catch (error) {
			console.log('User need to login.', error)
			this.mounted && this.setState({ checking: false })
		}
	}

	// performSessionLogic = (settingsRes, redirect) => {
	// 	const encryptedAccessTokenRes = localStorage.getItem(keys.accessToken)
	// 	if (encryptedAccessTokenRes) {
	// 		// Decrypt the access token response
	// 		const decryptedTokenRes = aes.decrypt(
	// 			encryptedAccessTokenRes,
	// 			settingsRes.data.oauth_clientISbb_oauth_clientsID
	// 		)
	// 		const accessTokenRes = JSON.parse(decryptedTokenRes.toString(encUTF8))
	// 		console.log('Decrypted: ', accessTokenRes)
	// 		// Check for expired token
	// 		const currentTime = Math.floor(Date.now() / 1000)
	// 		if (accessTokenRes.expires_in < currentTime) {
	// 			/* Token is invalid */
	// 			// So, Remove from localStorage
	// 			localStorage.removeItem(keys.accessToken)
	// 			this.updateRedirectAfterLoginVal(redirect)
	// 		} else {
	// 			/* Token is still valid */
	// 			// Set the access token to axios 'Authorization' header
	// 			setHeaderAccessToken(accessTokenRes.access_token)
	// 			this.mounted && this.setState({ loading: false })
	// 			// Store access token response to redux
	// 			// store.dispatch(setAccessToken(accessTokenRes)) // This will kick user to home
	// 		}
	// 	} else {
	// 		this.updateRedirectAfterLoginVal(redirect)
	// 	}
	// }

	updateRedirectAfterLoginVal = (val) => {
		if (val !== '/' && val !== '/login') {
			window[keys.redirectAfterLogin] = val
		}
	}

	componentWillUnmount() {
		this.mounted = false
	}

	render() {
		const { loading, checking, error } = this.state

		if (loading && error) return <LoadingCenter msg={error} msgColor='tomato' simple={true} />
		if (loading || checking) return <SplashScreenLoading msg='' simple={true} />

		return (
			<>
				{/* Online Content */}
				<Online polling={getPollingConf(store)}>
					<Provider store={store}>
						<ErrorBoundary>
							<HelmetHeader />
							<Router history={appRouteHistory}>
								<React.Suspense
									fallback={
										<LoadingCenter
											simple={true}
											msg='Loading Component...'
											msgColor={vars.emmaColor}
										/>
									}
								>
									<Switch>
										{/* Public */}
										<PublicRoute
											exact
											path='/login'
											name='Login'
											component={(props) => <Login {...props} />}
										/>
										<PublicRoute
											exact
											path='/otp'
											name='OTP'
											component={(props) => <OTP {...props} />}
										/>
										<PublicRoute
											exact
											path='/forgot-password'
											name='Request Password Reset Link'
											component={(props) => <ForgotPassword {...props} />}
										/>
										<Route
											exact
											path='/reset-password'
											name='Password Reset'
											component={(props) => <ResetPassword {...props} />}
										/>
										{/* Private */}
										<PrivateRoute
											path='/'
											name='Home'
											component={(props) => <TheLayout {...props} />}
										/>
									</Switch>
								</React.Suspense>
							</Router>
						</ErrorBoundary>
					</Provider>
				</Online>
				{/* Offline Content */}
				<Offline polling={getPollingConf(store)}>
					<OfflineNotice />
				</Offline>
			</>
		)
	}
}

export default App
