import React from 'react'
import { Tag as ATag } from 'antd'

function Tag(props) {
	return (
		<ATag
			className={props.className || ''}
			style={{
				marginLeft: 5,
				padding: '5px 9px',
				border: props.bgColor ? 'none' : '2px solid silver',
				borderRadius: 4,
				fontWeight: 'bold',
				backgroundColor: props.bgColor,
				color: props.textColor || '#fff',
				...(props.style ?? {}),
			}}
		>
			{props.value}
		</ATag>
	)
}

export default React.memo(Tag)
