import React from 'react'
import axios from 'axios'
import CIcon from '@coreui/icons-react'
import { FaRegUser } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import confirm from 'antd/lib/modal/confirm'
import { message, Button, Tooltip } from 'antd'
import { FaArrowRightFromBracket } from 'react-icons/fa6'
import { LockTwoTone, QuestionCircleTwoTone } from '@ant-design/icons'
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react'

import vars from '../config/vars'
// import keys from '../config/keys'
import { appRouteHistory } from '../App'
import endpoints from '../config/endpoints'
import handleError from '../utils/handleError'
import { centerClass } from '../components/Utils'
import DropdownButton from '../components/DropdownButton'

export const handleForgotPassword = async () => {
	try {
		const response = await axios.get(endpoints.forgotPassword)
		console.log('Forgot password response:', response.data)
		message.success('A password reset link has been successfully sent to your email address.')
	} catch (error) {
		handleError(error, true)
	}
}

const TheHeaderDropdown = () => {
	const userState = useSelector((state) => state.auth?.user)
	const { logout_redirect_url } = useSelector((state) => state.settings?.settings)
	const userIsClient = !!userState?.permissions?.client
	const userIsAdmin = !!userState?.permissions?.admin

	const isSpecialUI = userIsClient || userIsAdmin

	// const accessTokenRes = useSelector((state) => state.auth.accessToken)
	const { first_name, last_name } = userState?.user || {}
	// console.log(userState)

	const handleLogout = async () => {
		const hide = message.loading('Logging off...', 0)
		try {
			// const postData = { token: accessTokenRes.access_token, token_type_hint: 'access_token' }
			// console.log('Logout post data:', postData)
			// const logoutRes = await axios.post(endpoints.authRevokeEndpoint, postData)
			const logoutRes = await axios.get(endpoints.logout)
			console.log('Logout response:', logoutRes.data)
			// localStorage.removeItem(keys.accessToken)
			if (logout_redirect_url) {
				window.location.replace(logout_redirect_url)
			} else {
				setTimeout(() => window.location.reload(), 500)
			}
		} catch (error) {
			handleError(error, true)
		} finally {
			hide()
		}
	}
	window.handleLogout = handleLogout

	const showConfirm = (
		callback,
		title = 'Are you sure about this action?',
		desc,
		icon = <QuestionCircleTwoTone />
	) => {
		confirm({
			title,
			icon,
			content: desc,
			onOk: () => callback(),
			onCancel() {
				console.log('Action cancelled.')
			},
			okText: 'Yes',
			okType: 'primary',
		})
	}

	const goToProfile = () => appRouteHistory.push('/data/bb_users/profile')

	const userIconEl = <CIcon name='cil-user' className='mfe-2' />
	const label = first_name + ' ' + last_name

	if (isSpecialUI) {
		return (
			<div className='d-flex align-items-center'>
				<Tooltip title='Your Profile' placement='bottom'>
					<Button
						type='primary'
						className={centerClass + ' mr-1 font-weight-bold'}
						onClick={goToProfile}
					>
						<FaRegUser size={16} />
						&nbsp;
						{label}
					</Button>
				</Tooltip>
				<Tooltip title='Log Out' placement='bottomRight'>
					<Button type='' className={centerClass + ' px-2'} onClick={handleLogout}>
						<FaArrowRightFromBracket size={18} color={`rgba(0, 0, 21, 0.5)`} />
					</Button>
				</Tooltip>
			</div>
		)
	}

	return (
		<>
			<CDropdown inNav className='c-header-nav-items mx-0' direction='down'>
				<CDropdownToggle className='c-header-nav-link' caret={false} style={{ paddingLeft: 0 }}>
					<DropdownButton label={label} />
				</CDropdownToggle>
				<CDropdownMenu className='pt-0' placement='bottom-start'>
					<CDropdownItem
						header
						tag='div'
						// color={userIsClient ? '' : 'light'}
						className='text-center'
						style={{
							color: '#fff',
							borderTopLeftRadius: 3,
							borderTopRightRadius: 3,
							backgroundColor: vars.emmaDarkGreen,
						}}
					>
						<strong>{userIsClient ? 'Your Profile' : 'Account'}</strong>
					</CDropdownItem>
					<CDropdownItem onClick={goToProfile}>
						{userIconEl}
						Profile
					</CDropdownItem>
					<CDropdownItem
						onClick={() =>
							showConfirm(
								handleForgotPassword,
								'Please confirm you would like to send a password reset link to your email?',
								'',
								<LockTwoTone />
							)
						}
					>
						<CIcon name='cil-lock-locked' className='mfe-2' />
						Reset Password
					</CDropdownItem>
					<CDropdownItem divider />
					<CDropdownItem onClick={handleLogout}>
						<CIcon name='cil-account-logout' className='mfe-2' />
						Logout
					</CDropdownItem>
				</CDropdownMenu>
			</CDropdown>
		</>
	)
}

export default TheHeaderDropdown
