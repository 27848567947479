import { UPDATE_VOUCHER_FILTERS } from '../actions/types'

const initialState = { value: undefined, type: 'all', result: [] }

export default function (state = initialState, action) {
	switch (action.type) {
		case UPDATE_VOUCHER_FILTERS:
			return { ...state, ...action.payload }
		default:
			return state
	}
}
