import Axios from 'axios'

import keys from '../config/keys'
import store from '../redux/store'
import endpoints from '../config/endpoints'
import { updateThemeViaCssVar } from './updateTheme'
import { setSettings } from '../redux/actions/settingsActions'
import { localBuildNumber, localVersionNumber } from '../config/vars'

export const getAppSettings = async () => {
	const { data: settingsData } = await Axios.get(endpoints.settings)
	console.log('App Settings: ', settingsData)
	updateThemeViaCssVar(settingsData)

	const splash_logo = settingsData?.splash_logoISfile?.uri
	if (splash_logo) localStorage.setItem(keys.SPLASH_LOGO, splash_logo)

	const splash_bg_color = settingsData?.splash_background_color ?? ''
	if (splash_bg_color) localStorage.setItem(keys.SPLASH_BG_COLOR, splash_bg_color)

	// Change app title
	document.title = settingsData.title
	store.dispatch(setSettings(settingsData))

	window[keys.appVersion] = localVersionNumber
	window[keys.appBuildNumber] = localBuildNumber

	return settingsData
}
