import vars from '../../config/vars'
import {
	UPDATE_VIEWLIST_STATE,
	SET_VIEWLIST_LOADING_STATE,
	RESET_VIEWLIST_STATE,
} from '../actions/types'

const initialState = {
	initialCall: true,
	loading: true,
	data: [],
	dataResObj: {},
	columnDefs: [],
	paginationPageSize: vars.paginationPageSizeOptions[0], // limit
	paginationCurrentPage: 1, // page
	selectedTableRowKeys: [],
	searchText: '',
	dateRange: ['', ''],
	textFilterField: 'all',
	dateFilterField: '',
	processing: false,
	sortByQuery: '',
}

export default function (state = initialState, action) {
	switch (action.type) {
		case UPDATE_VIEWLIST_STATE:
			return { ...state, initialCall: false, ...(action.payload ?? {}) }
		case SET_VIEWLIST_LOADING_STATE:
			return { ...state, loading: action.payload }
		case RESET_VIEWLIST_STATE:
			return { ...initialState, ...(action.payload ?? {}) }
		default:
			return state
	}
}
