import React from 'react'
import { Spin } from 'antd'

import Loading from './index'

/* props can be -> color, msg, msgColor, opacity */
function LoadingCenter(props) {
	const { style = {} } = props
	return (
		<div
			style={{
				height: '100vh',
				width: '100vw',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: props.bgColor ? props.bgColor : 'transparent',
				zIndex: 999999,
				...style,
			}}
		>
			{props.spin ? <Spin size={props.size} /> : <Loading {...props} />}
		</div>
	)
}

export default LoadingCenter
