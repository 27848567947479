import { SET_CURRENT_DATA } from '../actions/types'

const initialState = {
	viewAndEdit: null,
	viewlist: null,
	editFormSaved: true,
	pageAsModal: null,
	addActivityMgmtRows: [],
	lookupFieldAdditionalOptions: {}, // { fieldKey: [{Option1}, {Option2},...], ...}
	emma_guests_raffle_results: {}, // { loading: bool, list: array }
	maintenanceEta: '',
}

export default function (state = initialState, action) {
	switch (action.type) {
		case SET_CURRENT_DATA:
			return { ...state, ...action.payload }
		default:
			return state
	}
}
