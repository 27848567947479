export const isNewFileSelected = (file) =>
	file &&
	typeof file === 'object' &&
	(!file.hasOwnProperty('thumbnail') || !file.hasOwnProperty('uri'))

export const getSizeFromBase64 = (base64String) => {
	// Without 'data:image/png;base64,' part
	const stringLength = base64String.length
	const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
	return sizeInBytes
}

const getBase64Prefix = (type) => `data:${type};base64,`
const commonImageTypes = ['png', 'jpeg', 'gif', 'apng', 'avif', 'webp', 'svg+xml']
export const cleanBase64ForAjax = (data, type, fileType = 'image/') => {
	let _data = data
	commonImageTypes.forEach((__type) => {
		_data = _data.replace(getBase64Prefix(`${fileType}${__type}`), '')
	})
	if (type) {
		_data = _data.replace(getBase64Prefix(type), '')
	}
	// For unknown type
	_data = _data.replace(getBase64Prefix(`application/octet-stream`), '')
	return _data
}

export const getUploadedFileObjForAjax = (file, data) => {
	const type = file.type
	let _data = cleanBase64ForAjax(data, type)

	return {
		name: file.filename,
		size: getSizeFromBase64(_data),
		type,
		data: _data,
	}
}

const plainTxtType = 'text/plain'
const txtExtension = '.txt'
export const getFileObjForAjax = (file, data, needClean = true) => {
	const type = file.type || plainTxtType
	const suffix = type === plainTxtType && !file.name?.endsWith?.(txtExtension) ? txtExtension : ''
	return {
		name: (file.name || file.filename) + suffix,
		size: file.size,
		type,
		data: needClean ? cleanBase64ForAjax(data, type) : data,
	}
}
